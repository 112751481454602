var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h2',{staticClass:"header font-weight-thin text-h2"},[_vm._v("Boarding")]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',{staticClass:"text"},[_vm._v("Overview of OnBoarding and OffBoarding people")]),_c('v-card-actions',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"text":"","to":"/onBoarding","icon":"","color":"black lighten-2"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"link":""}},[_vm._v("mdi-account-plus")])],1)]}}])},[_c('span',[_vm._v("Add person to onboarding")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"text":"","to":"/offBoarding","icon":"","color":"black lighten-2"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"link":""}},[_vm._v("mdi-account-minus")])],1)]}}])},[_c('span',[_vm._v("Add person to offboarding")])])],1)],1),_c('v-card',{staticClass:"card"},[_c('v-card-title',[_vm._v("Onboarding "),_c('v-spacer',{staticClass:"space"}),_c('v-text-field',{attrs:{"variant":"underlined","label":"Search","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.onBoardingSearch),callback:function ($$v) {_vm.onBoardingSearch=$$v},expression:"onBoardingSearch"}})],1),(
        _vm.alert && Object.keys(_vm.GET_OnBoardingAndOffBoardingPeople).length <= 0
      )?_c('ErrorMessageAlert',{attrs:{"text":_vm.GET_LayoutErrorMessage.message},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}}):_vm._e(),(Object.keys(_vm.GET_OnBoardingAndOffBoardingPeople).length <= 0)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"table: table-thead, table-tbody"}}):_c('v-data-table',{staticClass:"elevation-1 data-table",staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.GET_OnBoardingAndOffBoardingPeople.onBoarding,"search":_vm.onBoardingSearch,"hide-default-footer":true,"items-per-page":_vm.itemsPerPage,"page":_vm.pageOnBoarding},on:{"update:page":function($event){_vm.pageOnBoarding=$event},"page-count":function($event){_vm.pageCountOnBoarding = $event},"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.total",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.tasksCompleted)+" / "+_vm._s(item.tasksTotal)+" ")]}}],null,true)}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCountOnBoarding},model:{value:(_vm.pageOnBoarding),callback:function ($$v) {_vm.pageOnBoarding=$$v},expression:"pageOnBoarding"}})],1)],1),_c('div',{staticClass:"pt-10 pb-10"}),_c('v-card',{staticClass:"mt-15 card"},[_c('v-card-title',[_vm._v("Offboarding "),_c('v-spacer',{staticClass:"space"}),_c('v-text-field',{attrs:{"variant":"underlined","label":"Search","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.offBoardingSearch),callback:function ($$v) {_vm.offBoardingSearch=$$v},expression:"offBoardingSearch"}})],1),(Object.keys(_vm.GET_OnBoardingAndOffBoardingPeople).length <= 0)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"table: table-thead, table-tbody"}}):_c('v-data-table',{staticClass:"elevation-1 data-table",staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.GET_OnBoardingAndOffBoardingPeople.offBoarding,"search":_vm.offBoardingSearch,"hide-default-footer":true,"items-per-page":_vm.itemsPerPage,"page":_vm.pageOffBoarding},on:{"update:page":function($event){_vm.pageOffBoarding=$event},"page-count":function($event){_vm.pageCountOffBoarding = $event}},scopedSlots:_vm._u([{key:"item.total",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.tasksCompleted)+" / "+_vm._s(item.tasksTotal)+" ")]}}],null,true)}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCountOffBoarding},model:{value:(_vm.pageOffBoarding),callback:function ($$v) {_vm.pageOffBoarding=$$v},expression:"pageOffBoarding"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }