<template>
  <v-row>
    <v-dialog
      v-model="GET_OpenShowSelectedOnBoardingTask"
      persistent
      max-width="700px"
    >
      <v-card>
        <v-card-title class="header font-weight-thin text-h4">{{
          GET_SelectedOnBoardingTask.text
        }}</v-card-title>
        <v-form class="form">
          <v-row
            ><v-col cols="12" sm="6"
              ><v-text-field
                :value="GET_SelectedOnBoardingTask.type"
                label="Time frame"
                readonly
                :disabled="GET_SelectedOnBoardingTask.createdBy === ''"
              ></v-text-field></v-col
            ><v-col cols="12" sm="6"
              ><v-text-field
                :value="GET_SelectedOnBoardingTask.assignedTo"
                label="Assigned to"
                readonly
                :disabled="GET_SelectedOnBoardingTask.assignedTo === ''"
              ></v-text-field></v-col
          ></v-row>
          <v-row
            ><v-col cols="12" sm="6" class="last-text-field"
              ><v-text-field
                :value="GET_SelectedOnBoardingTask.emailTemplateName"
                label="Email action"
                readonly
                :disabled="GET_SelectedOnBoardingTask.emailTemplateName === ''"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6"
              ><v-textarea
                :value="GET_SelectedOnBoardingTask.description"
                :disabled="GET_SelectedOnBoardingTask.description === ''"
                outlined
                auto-grow
                label="Description"
                rows="3"
              ></v-textarea
            ></v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="primary"
              class="action-btn"
              @click="SET_OpenShowSelectedOnBoardingTask(false)"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("onBoardingStore", [
      "GET_OpenShowSelectedOnBoardingTask",
      "GET_SelectedOnBoardingTask",
    ]),
  },
  methods: {
    ...mapMutations("onBoardingStore", ["SET_OpenShowSelectedOnBoardingTask"]),
  },
};
</script>

<style scoped lang='scss'>
.header {
  margin-bottom: 16px;
  padding-bottom: 30px;
  justify-content: center;
}
.form {
  padding: 16px 24px 10px;
}
.last-text-field {
  margin-top: 29px;
}
.action-btn {
  font-weight: 300;
}
</style>