<template>
  <v-row>
    <v-dialog
      v-model="GET_OpenEditSelectedPersonInfo"
      persistent
      max-width="900px"
      ><v-card
        ><v-card-title class="header font-weight-thin text-h4"
          >Do you want to edit &nbsp;
          <b
            >{{ GET_SelectedPersonWithTasks.firstName }}
            {{ GET_SelectedPersonWithTasks.lastName }}
          </b>
          ? </v-card-title
        ><v-form ref="form" v-model="valid" :lazy-validation="lazy" class="form"
          ><v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="person.privateInfo.email"
                  :rules="[rules.required, rules.email]"
                  label="Private E-mail*"
                  required
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="person.privateInfo.phone"
                  :rules="[rules.required, rules.phone]"
                  label="Private Phone number*"
                  required
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="person.firstName"
                  :rules="[rules.required]"
                  label="First name*"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="person.lastName"
                  :rules="[rules.required]"
                  label="Last name*"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="person.jobInfo.title"
                  label="Job title*"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="geshdoMail"
                  :items="employeesInfo"
                  :menu-props="{ top: false, offsetY: true }"
                  @focus="$event.target.click()"
                  item-text="mail"
                  label="GESHDO e-mail"
                  return-object
                  attach
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="person.jobInfo.office"
                  @focus="$event.target.click()"
                  :items="offices"
                  :menu-props="{ top: false, offsetY: true }"
                  label="Office*"
                  required
                  attach
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="person.jobInfo.manager"
                  @focus="$event.target.click()"
                  :items="managers"
                  :menu-props="{ top: false, offsetY: true }"
                  label="Manager*"
                  required
                  attach
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  :value="GET_SelectedPersonWithTasks.templateName"
                  label="OnBoarding Template"
                  readonly
                  hint="You cant edit OnBoarding template"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-menu
                  ref="startMenu"
                  v-model="startMenu"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="person.startDate"
                      label="Start Date*"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[(v) => !!v || 'Date is required']"
                      required
                    />
                  </template>
                  <v-date-picker
                    v-model="person.startDate"
                    @input="startMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row class="last-row">
              <v-col cols="12" sm="6">
                <v-combobox
                  v-model="person.equipment"
                  :items="equipment"
                  label="Select borrowed equipment"
                  multiple
                  chips
                  ><template
                    v-slot:selection="{ attrs, item, parent, selected }"
                    ><v-chip
                      :key="JSON.stringify(item)"
                      :input-value="selected"
                      v-bind="attrs"
                    >
                      <span
                        >{{ item }}
                        <v-icon small @click="parent.selectItem(item)"
                          >$delete</v-icon
                        ></span
                      >
                    </v-chip></template
                  >
                </v-combobox></v-col
              >
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="d-flex flex-column"
                ><small>*indicates required field</small>
                <ErrorMessageAlert
                  v-if="alert"
                  v-model="alert"
                  :text="GET_OnOffBoardingErrorMessage.message"
                />
              </v-col>
              <v-col>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn depressed color="primary" class="btn" @click="reset()">
                    Cancel
                  </v-btn>
                  <v-btn
                    depressed
                    color="primary"
                    class="btn"
                    @click="savePerson()"
                    :disabled="isDisabled"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
            <v-spacer></v-spacer>
          </v-container> </v-form></v-card
    ></v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getAllCompanyUsers } from "../../services/msGraphActions.js";
import ErrorMessageAlert from "../MessageToUser/ErrorMessageAlert.vue";
export default {
  components: { ErrorMessageAlert },
  data() {
    return {
      alert: false,
      valid: true,
      lazy: true,
      startMenu: false,
      date: null,
      offices: ["Malmö", "Göteborg", "Remote"],
      owners: [],
      employees: null,
      geshdoMail: {},
      equipment: [
        "MacBook",
        "Dell pc",
        "Keyboard",
        "Headphone",
        "Ipad",
        "Mobile phone",
      ],
      person: {
        firstName: "",
        lastName: "",
        startDate: "",
        templateId: "",
        equipment: [],
        privateInfo: {
          email: "",
          phone: "",
        },
        jobInfo: {
          office: "",
          manager: "",
          title: "",
        },
      },
      rules: {
        required: (value) => !!value || "Required",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        phone: (value) => {
          if (!value) {
            return true;
          } else {
            const pattern =
              /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

            return (
              pattern.test(value.replace(/ /g, "")) || "Invalid phone number."
            );
          }
        },
      },
    };
  },
  computed: {
    ...mapGetters("onBoardingStore", [
      "GET_OpenEditSelectedPersonInfo",
      "GET_SelectedPersonWithTasks",
      "GET_OnOffBoardingErrorMessage",
    ]),

    managers() {
      try {
        const information = this.employees?.value.map((value) => {
          return value.displayName;
        });
        return information;
      } catch {
        return [];
      }
    },

    employeesInfo() {
      try {
        const information = this.employees?.value.map((value) => {
          return (value = { mail: value.mail, id: value.id });
        });

        information.unshift({ mail: "", id: "" });
        return information;
      } catch {
        return [];
      }
    },

    isDisabled() {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const phone = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
      return (
        !pattern.test(this.person.privateInfo.email) ||
        !phone.test(this.person.privateInfo.phone.replace(/ /g, "")) ||
        this.person.firstName == "" ||
        this.person.lastName == "" ||
        this.person.startDate == "" ||
        this.person.jobInfo.office == "" ||
        this.person.jobInfo.manager == "" ||
        this.person.jobInfo.title == ""
      );
    },
  },
  async created() {
    this.employees = await getAllCompanyUsers();
    this.selectedPerson();
  },
  watch: {
    alert(newValue) {
      if (newValue === false) {
        this.SET_OnOffBoardingErrorMessage({});
      }
    },
  },
  methods: {
    ...mapMutations("onBoardingStore", [
      "SET_OpenEditSelectedPersonInfo",
      "SET_OnOffBoardingErrorMessage",
    ]),
    ...mapActions("onBoardingStore", [
      "EDIT_OnBoardingAndOffBoardingPersonInfo",
    ]),

    selectedPerson() {
      const selectPerson = JSON.parse(
        JSON.stringify(this.GET_SelectedPersonWithTasks)
      );
      this.geshdoMail = {
        mail: selectPerson.jobInfo.workEmail,
        id: selectPerson.jobInfo.id,
      };
      this.person = selectPerson;
    },

    reset() {
      this.$refs.form.reset();
      this.SET_OpenEditSelectedPersonInfo(false);
    },

    async savePerson() {
      this.alert = false;
      this.$refs.form.validate();
      this.person.jobInfo.workEmail = this.geshdoMail.mail;
      this.person.jobInfo.azureId = this.geshdoMail.id;

      const template = {
        type: "onBoarding",
        item: this.person,
      };

      await this.EDIT_OnBoardingAndOffBoardingPersonInfo(template);
      if (
        this.GET_OnOffBoardingErrorMessage.alert &&
        this.GET_OnOffBoardingErrorMessage.alert === true
      ) {
        this.alert = true;
        return;
      }
      this.SET_OpenEditSelectedPersonInfo(false);
    },
  },
};
</script>

<style scoped>
.form {
  padding: 16px 24px 10px;
}

.btn {
  margin: 26px 0px;
  font-weight: 300;
}
</style>