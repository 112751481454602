import {getApiData, postApiData, patchApiData, deleteApiData} from '../api/api';

const settingStore = {
  namespaced: true,
  state: {
    settingsList: [
      {
        icon: "mdi-account-plus",
        text: "Onboarding templates",
        link: "/settings/",
        shortName: "onBoarding",
      },
      {
        icon: "mdi-account-minus",
        text: "Offboarding templates",
        link: "/settings/",
        shortName: "offBoarding",
      },
     {
        icon: "mdi-note-text",
        text: "Mail templates",
        link: "/settings/Mail",
        shortName: "Mail",
      },
      {
        icon: "mdi-account-edit",
        text: "Manage People",
        link: "/settings/ManagePeople",
        shortName: "ManagePeople",
      },
    ],
    templateType: null,
    selectedTemplatesForTemplateType : {},
    selectedTemplateWithCategory: {},
    selectedTemplates: null,
    selectedTask: {},
    onBoardingAndOffBoardingPeople: [],
    selectedPerson: {},
    mailTemplates: [],
    selectedMailTemplate: {},
    errorMessage : {},

    openCreateTemplateDialog: false,
    openEditTemplateDialog: false,
    openDeleteTemplateDialog: false,
    newOrUpdatedTemplate: false,
    openCreateTaskDialog: false,
    openEditTaskDialog: false,
    openDeleteTaskDialog: false,
    newOrUpdatedTask: false,
    openDeletePersonDialog: false,
    newOrUpdatedPersonDelete: false,
    openCreateMailTemplateDialog: false,
    openEditMailTemplateDialog: false,
    openDeleteMailTemplateDialog: false,
    newOrUpdatedMailTemplate: false,
  },
  getters: {
    GET_SettingsList: (state) => state.settingsList,
    GET_TemplateType: (state) => state.templateType,
    GET_SelectedTemplatesForTemplateType: (state) => state.selectedTemplatesForTemplateType,
    GET_SelectedTemplateWithCategory: (state) => state.selectedTemplateWithCategory,
    GET_SelectedTask: (state) => state.selectedTask,
    GET_OnBoardingAndOffBoardingPeople: (state) => state.onBoardingAndOffBoardingPeople,
    GET_SelectedPerson: (state) => state.selectedPerson,
    GET_MailTemplates: (state) => state.mailTemplates,
    GET_SelectedMailTemplate: (state) => state.selectedMailTemplate,
    GET_ErrorMessage: (state) => state.errorMessage,

    GET_OpenCreateTemplateDialog: (state) => state.openCreateTemplateDialog,
    GET_OpenEditTemplateDialog: (state) => state.openEditTemplateDialog,
    GET_OpenDeleteTemplateDialog: (state) => state.openDeleteTemplateDialog,
    GET_NewOrUpdatedTemplate: (state) => state.newOrUpdatedTemplate,
    GET_OpenCreateTaskDialog: (state) => state.openCreateTaskDialog,
    GET_OpenEditTaskDialog: (state) => state.openEditTaskDialog,
    GET_OpenDeleteTaskDialog: (state) => state.openDeleteTaskDialog,
    GET_NewOrUpdatedTask: (state) => state.newOrUpdatedTask,
    GET_OpenDeletePersonDialog: (state) => state.openDeletePersonDialog,
    GET_NewOrUpdatedPersonDelete: (state) => state.newOrUpdatedPersonDelete,
    GET_OpenCreateMailTemplateDialog: (state) => state.openCreateMailTemplateDialog,
    GET_OpenEditMailTemplateDialog: (state) => state.openEditMailTemplateDialog, 
    GET_OpenDeleteMailTemplateDialog: (state) => state.openDeleteMailTemplateDialog,
    GET_NewOrUpdatedMailTemplate: (state) => state.newOrUpdatedMailTemplate,

    
    
    
    
  },
  mutations: {
    SET_SettingsList(state, updateSettingsList){
      state.settingsList = updateSettingsList
    }, 
    SET_TemplateType(state, updateTemplateType){
      state.templateType = updateTemplateType
    },
    SET_SelectedTemplatesForTemplateType(state, updatedSelectedTemplatesForTemplateType){
      state.selectedTemplatesForTemplateType = updatedSelectedTemplatesForTemplateType
    },
    SET_SelectedTemplateWithCategory(state, updatedSelectedTemplateWithCategory){
      state.selectedTemplateWithCategory = updatedSelectedTemplateWithCategory
    }, 
    SET_SelectedTask(state,updatedSelectedTask){
      state.selectedTask = updatedSelectedTask
    },
    SET_OnBoardingAndOffBoardingPeople(state, updateOnBoardingAndOffBoardingPeople){
    state.onBoardingAndOffBoardingPeople = updateOnBoardingAndOffBoardingPeople
    },
    SET_SelectedPerson(state, updatedSelectedPerson){
      state.selectedPerson = updatedSelectedPerson
    },
    SET_MailTemplates(state, updatedMailTemplates){
      state.mailTemplates = updatedMailTemplates
    },
    SET_SelectedMailTemplate(state, updatedSelectedMailTemplate){
      state.selectedMailTemplate = updatedSelectedMailTemplate
    },
    SET_ErrorMessage(state, updateErrorMessage){
      state.errorMessage = updateErrorMessage
    },

    SET_OpenCreateTemplateDialog(state, boolean){
      state.openCreateTemplateDialog = boolean
    },
    SET_OpenEditTemplateDialog(state, boolean){
      state.openEditTemplateDialog = boolean
    },
    SET_OpenDeleteTemplateDialog(state, boolean){
      state.openDeleteTemplateDialog = boolean
    },
    SET_NewOrUpdatedTemplate(state, boolean){
      state.newOrUpdatedTemplate = boolean
    },
    SET_OpenCreateTaskDialog(state, boolean){
      state.openCreateTaskDialog = boolean
    },
    SET_OpenEditTaskDialog(state, boolean){
      state.openEditTaskDialog = boolean
    },
    SET_OpenDeleteTaskDialog(state, boolean){
      state.openDeleteTaskDialog = boolean
    },
    SET_NewOrUpdatedTask(state, boolean){
      state.newOrUpdatedTask = boolean
    },
    SET_OpenDeletePersonDialog(state, boolean){
      state.openDeletePersonDialog = boolean
    },
    SET_NewOrUpdatedPeopleDelete(state, boolean){
      state.newOrUpdatedPersonDelete = boolean
    },
    SET_OpenCreateMailTemplateDialog(state, boolean){
      state.openCreateMailTemplateDialog = boolean
    },
    SET_OpenEditMailTemplateDialog(state, boolean){
      state.openEditMailTemplateDialog = boolean
    },
    SET_OpenDeleteMailTemplateDialog(state, boolean){
      state.openDeleteMailTemplateDialog = boolean
    },
    SET_NewOrUpdatedMailTemplate(state, boolean){
      state.newOrUpdatedMailTemplate = boolean
    },

  },
  actions: {

    //-----------Template -----------------//

    async FETCH_TemplatesForSelectedTemplateType(context, templateType){
      const json = false;
      try{
        const response = await getApiData(`template/${templateType}`, json);
        if(response.status === 200){
          const result = await response.json();
          await context.commit('SET_SelectedTemplatesForTemplateType', result.data)
        } else {
          await context.commit('SET_ErrorMessage', {alert: true, message: 'Failed to fetch templates, please refesh the page. If it doesnt work contact the administrator. ' })
        }
      } catch(err){
        console.log('error')
        
        return
      }
    }, 

    async FETCH_SelectedTemplateWithCategory(context, item){
      const json = false;
      try {
        const response = await getApiData(`template/${item.templateType}/${item.id}`, json);
        if(response.status === 200){
          const result = await response.json();
          await context.commit('SET_SelectedTemplateWithCategory', result)
        } else {
          console.log(response)
        }
      } catch(err){
        console.log('error')
        return 
      }
    },

    async POST_NewTemplate(context, template){
      const json = false;
      try{
        const response = await postApiData(`template/`, template , json
        );
        if (response.status === 201) {
          context.commit('SET_NewOrUpdatedTemplate', true)
       } else if (response.status === 400)  {
        await context.commit('SET_ErrorMessage', {alert: true, message: 'Template already exists. Please try again with a new title' })
       } else {
        await context.commit('SET_ErrorMessage', {alert: true, message: "Couldn't save the new template, please try again" })
       }
      } catch(err){ 
        console.log('error')
        return err
      }
       
      },
    

    async EDIT_SelectedTemplateWithCategory(context, item){
      const json = false;
      try{
        const response = await patchApiData(`template/${item.templateNameWithTemplateType.category}/${item.itemId}`, item.templateNameWithTemplateType, json);
        if(response.status === 200){
          context.commit('SET_NewOrUpdatedTemplate', true)
        } else {
          await context.commit('SET_ErrorMessage', {alert: true,  message : 'Failed to edit template, please try again.' })
        }
        return response
      } catch(err){
        console.log('error')
        return err
      }
    },

    async DELETE_SelectedTemplate(context, item){
      const json = false;
      try {
        const response = await deleteApiData(`template/${item.templateType}/${item.id}` ,json)
        if(response.status === 201){
          context.commit('SET_NewOrUpdatedTemplate', true)
        } else {
          await context.commit('SET_ErrorMessage', {alert: true,  message : 'Failed to delete the selected template, please try again.' })
        }
        return response
      } catch(err){
        console.log('error')
        return err
      }
    },
   
    //-----------Tasks--------------------//

    async POST_NewTask(context, task){
      const json = false;
      try{
        const response = await postApiData(`template/${task[1].category}/${task[1].id}/task`, task[0], json )
        if(response.status === 201){
         context.commit("SET_NewOrUpdatedTask", true)
        } else {
          await context.commit('SET_ErrorMessage', {alert: true,  message : 'Failed to create a new task, please try again.' })
        }
      }catch (err){
        console.log('error')
        return err
      }
    },

    async EDIT_SelectedTask(context, item){
      const json = false;
      try {
        const response = await patchApiData(`template/${item[1].category}/${item[1].id}/task/${item[0].id}`,item[0], json)
        if(response.status === 201){
          context.commit('SET_NewOrUpdatedTask', true)
        } else {
          await context.commit('SET_ErrorMessage', {alert: true,  message : 'Failed to edit the selected task, please try again.' })
        }
        return response
      } catch(err){
        console.log('error')
        return err
      }
    },

    async DELETE_SelectedTask(context, item){
      const json = false;
      try {
        const response = await deleteApiData(`template/${item.templateType}/${item.templateId}/task/${item.id}` ,json)
        if(response.status === 201){
          context.commit('SET_NewOrUpdatedTask', true)
        } else {
          await context.commit('SET_ErrorMessage', {alert: true,  message : 'Failed to delete the selected task, please try again.' })
        }
        return response
      } catch(err){
        console.log('error')
        return err
      }
    },

    //-----------Mail---------------------//

    async FETCH_MailTemplates(context){
      const json = false;
      try{
        const response = await getApiData(`mailTemplate`,  json);
        if(response.status === 200){
          const result = await response.json();
          await context.commit('SET_MailTemplates',  result.data) 
        } else {
          await context.commit('SET_ErrorMessage', {alert: true,  message : 'Failed to fetch email templates, please try again.' })
        }
        return response
      } catch(err){
        console.log('error')
        return
      }
    }, 

   

    async FETCH_SelectedMailTemplate(context, id){
      const json = false;
      try{
        const response = await getApiData(`mailTemplate/${id}`, json);
        if(response.status === 200){
          const result = await response.json();
          await context.commit('SET_SelectedMailTemplate',  result.data) 
        } else {
          await context.commit('SET_ErrorMessage', {alert: true,  message : 'Failed to fetch selected email template, please try again.' })
        }
        return response
      } catch(err){
        console.log('error')
        return
      }
    },

    async POST_NewMailTemplate(context, selected){
      const json = false;
      
      try{
        const response = await postApiData(`mailTemplate`, selected, json)
       if(response.status === 201){
          context.commit('SET_NewOrUpdatedMailTemplate', true)
        }
      
        return response
      } catch(err){
        console.log('error')
        return err
      }
    },

    async EDIT_SelectedMailTemplate(context, selected){
      const json = false;
     
      try {
        const response = await patchApiData(`mailTemplate/${selected.id}`,selected, json)
        if(response.status === 201){
          context.commit('SET_NewOrUpdatedMailTemplate', true)
        }
        return response
      } catch(err){
        console.log('error')
        return err
      }
    },

    async DELETE_SelectedMailTemplate(context, id){
      const json = false;
      try {
        const response = await deleteApiData(`mailTemplate/${id}` ,json)
        
        if(response.status === 201){
          context.commit('SET_NewOrUpdatedMailTemplate', true)
        }
        return response
      } catch(err){
        console.log('error')
        return err
      }
    },

    


    //-----------DeletePerson------------//

    async FETCH_OnBoardingAndOffBoardingPeople(context){
      const json = false
      try{
        const response = await getApiData(`people/`, json);
        if(response.status === 200){
          const result = await response.json();
          await context.commit('SET_OnBoardingAndOffBoardingPeople',  result )
        } else {
          await context.commit('SET_ErrorMessage', {alert: true,  message : 'Failed to fetch list of managed persons, please try again.' })
        }
      } catch(err){
        console.log('error')
        return
      }
      
    },
    async DELETE_Person(context, person){
      const json = false;
      try {
        const response = await deleteApiData(`people/${person.type}/${person.item.id}` ,json)
    
        if(response.status === 200){
          context.commit('SET_NewOrUpdatedPeopleDelete', true)
        } else {
          await context.commit('SET_ErrorMessage', {alert: true,  message : 'Failed to delete selected person, please try again.' })
        }
        return response
      } catch(err){
        console.log('error')
        return err
      }
    }


    

  }

}

export default settingStore;