<template>
  <v-container>
    <h2 class="header font-weight-thin text-h2">Boarding</h2>
    <div class="d-flex justify-space-between">
      <p class="text">Overview of OnBoarding and OffBoarding people</p>
      <v-card-actions
        ><v-tooltip bottom
          ><template v-slot:activator="{ on, attrs }"
            ><v-btn
              class="ma-2"
              text
              :to="`/onBoarding`"
              icon
              color="black lighten-2"
              v-on="on"
              v-bind="attrs"
            >
              <v-icon link>mdi-account-plus</v-icon></v-btn
            ></template
          >
          <span>Add person to onboarding</span>
        </v-tooltip>
        <v-tooltip bottom
          ><template v-slot:activator="{ on, attrs }"
            ><v-btn
              class="ma-2"
              text
              :to="`/offBoarding`"
              icon
              color="black lighten-2"
              v-on="on"
              v-bind="attrs"
            >
              <v-icon link>mdi-account-minus</v-icon></v-btn
            ></template
          >
          <span>Add person to offboarding</span></v-tooltip
        ></v-card-actions
      >
    </div>
    <v-card class="card">
      <v-card-title
        >Onboarding <v-spacer class="space"></v-spacer
        ><v-text-field
          v-model="onBoardingSearch"
          variant="underlined"
          label="Search"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field
      ></v-card-title>
      <ErrorMessageAlert
        v-if="
          alert && Object.keys(GET_OnBoardingAndOffBoardingPeople).length <= 0
        "
        v-model="alert"
        :text="GET_LayoutErrorMessage.message"
      />
      <v-skeleton-loader
        class="mx-auto"
        type="table: table-thead, table-tbody"
        v-if="Object.keys(GET_OnBoardingAndOffBoardingPeople).length <= 0"
      ></v-skeleton-loader>

      <v-data-table
        v-else
        :headers="headers"
        :items="GET_OnBoardingAndOffBoardingPeople.onBoarding"
        :search="onBoardingSearch"
        :hide-default-footer="true"
        :items-per-page="itemsPerPage"
        :page.sync="pageOnBoarding"
        class="elevation-1 data-table"
        style="cursor: pointer"
        @page-count="pageCountOnBoarding = $event"
        @click:row="rowClick"
      >
        <template v-slot:[`item.total`]="{ item }">
          {{ item.tasksCompleted }} / {{ item.tasksTotal }}
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination
          v-model="pageOnBoarding"
          :length="pageCountOnBoarding"
        ></v-pagination>
        <!-- <v-text-field
          :value="itemsPerPage"
          label="Items per page"
          type="number"
          min="-1"
          max="15"
          @input="itemsPerPage = parseInt($event, 10)"
        ></v-text-field>-->
      </div>
    </v-card>
    <div class="pt-10 pb-10"></div>
    <v-card class="mt-15 card">
      <v-card-title
        >Offboarding <v-spacer class="space"></v-spacer
        ><v-text-field
          v-model="offBoardingSearch"
          variant="underlined"
          label="Search"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field
      ></v-card-title>
      <v-skeleton-loader
        class="mx-auto"
        type="table: table-thead, table-tbody"
        v-if="Object.keys(GET_OnBoardingAndOffBoardingPeople).length <= 0"
      ></v-skeleton-loader>
      <v-data-table
        v-else
        :headers="headers"
        :items="GET_OnBoardingAndOffBoardingPeople.offBoarding"
        :search="offBoardingSearch"
        :hide-default-footer="true"
        :items-per-page="itemsPerPage"
        :page.sync="pageOffBoarding"
        class="elevation-1 data-table"
        style="cursor: pointer"
        @page-count="pageCountOffBoarding = $event"
      >
        <template v-slot:[`item.total`]="{ item }">
          {{ item.tasksCompleted }} / {{ item.tasksTotal }}
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination
          v-model="pageOffBoarding"
          :length="pageCountOffBoarding"
        ></v-pagination>
      </div>
    </v-card>
  </v-container>
</template>


<script>
import { mapGetters, mapMutations } from "vuex";
import ErrorMessageAlert from "../../components/MessageToUser/ErrorMessageAlert.vue";
export default {
  components: { ErrorMessageAlert },
  data() {
    return {
      alert: false,
      pageOnBoarding: 0,
      pageOffBoarding: 0,
      pageCountOnBoarding: 0,
      pageCountOffBoarding: 0,
      itemsPerPage: 10,
      onBoardingSearch: "",
      offBoardingSearch: "",
    };
  },
  computed: {
    ...mapGetters("layoutStore", [
      "GET_OnBoardingAndOffBoardingPeople",
      "GET_LayoutErrorMessage",
    ]),
    headers() {
      return [
        { text: "Name", value: "name" },
        { text: "Total", value: "total" },
      ];
    },
    getOnAndOffBoardingEmployees() {
      return this.GET_OnBoardingAndOffBoardingPeople;
    },
  },
  created() {
    if (
      this.GET_LayoutErrorMessage &&
      Object.keys(this.GET_LayoutErrorMessage).length > 0
    ) {
      this.alert = true;
    }
  },
  watch: {
    GET_LayoutErrorMessage(newValue) {
      if (newValue && newValue.alert === true) {
        this.alert = true;
      }
    },
  },
  methods: {
    ...mapMutations("onBoardingStore", [
      "SET_NewOrUpdatedPerson",
      "SET_SelectedPersonWithTasks",
    ]),

    rowClick(item) {
      this.$router.push({
        name: "onProgress",
        params: { id: item.id },
      });
    },
  },
};
</script>


<style scoped lang="scss">
.header {
  margin-bottom: 16px;
  padding-bottom: 25px;
}
.header-name {
  font-size: 20px;
  font-weight: bold;
}
.text {
  padding-top: 8px;
  padding-bottom: 8px;
}
.space {
  width: 35%;
}
.data-table {
  margin: 0px 16px;
  // height: 528px;
}

.card ::v-deep .text-center {
  padding-bottom: 15px !important;
  padding-top: 15px !important;
}
</style>