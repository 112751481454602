let idToken = undefined;
let tokenType = undefined;

export const setTokens = (newidToken, newTokenType) => {
  idToken = newidToken;
  tokenType = newTokenType;
};

export const clearTokens = () => {
  idToken = undefined;
  Object.keys(localStorage)
    .filter((key) => !["navbarMinified", "theme"].includes(key))
    .forEach((key) => localStorage.removeItem(key));
};

// API
export const apiFetch = async (endpoint, options, json) => {
  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}${endpoint}`, {
      ...options,
      headers: {
        Authorization: ` ${tokenType} ${idToken}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 401) {
      clearTokens();
      location.reload();
      return undefined;
    } 
    if(json === false){
      return response;
    } else {
      return await response.json()
    };

    //return await response.json();
  } catch {
    return undefined;
  }
};

export const getApiData = async (endpoint, json) => {
  return apiFetch(endpoint, {
    method: "GET",
  }, json);
};

export const postApiData = async (endpoint, data, json) => {
  return apiFetch(endpoint, {
    method: "POST",
    body: data && JSON.stringify(data),
  }, json);
};

export const putApiData = async (url, data) => {
  return apiFetch(url, {
    method: "PUT",
    body: data && JSON.stringify(data),
  });
};

export const patchApiData = async (url, data, json) => {
  return apiFetch(url, {
    method: "PATCH",
    body: data && JSON.stringify(data),
  }, json);
};

export const deleteApiData = async (url, json) => {
  return apiFetch(url, { method: "DELETE" }, json);
};


