import Vue from "vue";

const onboardingPeople = {
  namespaced: true,
  state: {
    people: null,
    peopleLoading: false,
  },
  mutations: {
    setPeople(state, newData) {
      state.people = newData;
    },

    setPeopleLoading(state, newData) {
      state.peopleLoading = newData;
    },

    setCompletedTask(state, payLoad) {
      const person = state.people.find((x) => x.id === payLoad.id);

      const completed = payLoad.updatedTask.completed;
      person.tasks.map((existingTask) => {
        if (existingTask.id === payLoad.updatedTask.id) {
          Vue.set(existingTask, "completed", completed);
        }
        return existingTask;
      });
    },
    removeCompletedTask(state, payLoad) {
      const person = state.people.find((x) => x.id === payLoad.id);
      person.tasks.map((existingTask) => {
        if (existingTask.id === payLoad.updatedTask.id) {
          Vue.delete(existingTask, "completed");
        }
        return existingTask;
      });
    },
    deletePersonState(state, index) {
      Vue.delete(state.people, index);
    },
  }, // change state
  getters: {
    getPersonMonth: (state) => (id) => {
      const person = state.people.find((x) => x.id === id);
      return person.tasks.filter((task) => task.type === "month");
    },
    getPersonWeek: (state) => (id) => {
      const person = state.people.find((x) => x.id === id);
      return person.tasks.filter((task) => task.type === "week");
    },
    getPersonDay: (state) => (id) => {
      const person = state.people.find((x) => x.id === id);

      return person.tasks.filter((task) => task.type === "day");
    },

    getPersonTasks: (state) => (id) => {
      const person = state.people.find((x) => x.id === id);

      return person.tasks;
    },
    getTask: (state) => (personId, taskId) => {
      const person = state.people.find((x) => x.id === personId);
      const task = person.tasks.find((x) => x.id === taskId);

      return task;
    },
    getPersonWithTeamLead: (state) => (teamlead) => {
      const persons = state.people.find((x) => x.JobInfo.Team === teamlead);

      return persons;
    },
    getPerson: (state) => (id) => {
      const person = state.people.find((x) => x.id === id);

      return person;
    },
  }, // get specific state
  actions: {}, // tell what to do
};

export default onboardingPeople;
