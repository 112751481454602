<template>
  <v-container v-if="GET_SelectedTemplatesForTemplateType != null"
    ><h2 class="header font-weight-thin text-h2">
      {{ location.name }}
    </h2>
    <v-card
      ><v-card-title class="card-title-container"
        ><v-card-subtitle class="card-sub-title"
          >Create a new template</v-card-subtitle
        ><v-icon @click="SET_OpenCreateTemplateDialog(true)"
          >mdi-note-plus</v-icon
        ></v-card-title
      >
      <v-list v-if="alert">
        <v-list-item class="error-msg"
          ><ErrorMessageAlert
            v-if="alert"
            v-model="alert"
            :text="GET_ErrorMessage.message"
        /></v-list-item>
      </v-list>
      <v-list v-else
        ><v-list-item-group class="list-group"
          ><v-list-item
            v-for="(item, i) in GET_SelectedTemplatesForTemplateType"
            :key="i"
            class="list-item"
            ><v-list-item-content
              ><v-list-item-title
                class="title"
                link
                @click="openSelectedTemplate(item)"
                >{{ item.name }}</v-list-item-title
              ></v-list-item-content
            ><v-icon class="edit-icon" @click="openEditDialog(item)"
              >mdi-pencil</v-icon
            ><v-icon @click="openDeleteDialog(item)"
              >mdi-delete</v-icon
            ></v-list-item
          ></v-list-item-group
        ></v-list
      ></v-card
    >
    <v-btn class="back-btn" depressed color="primary" @click="goBack()"
      ><v-icon class="go-back-icon">mdi-keyboard-backspace</v-icon>Go
      back</v-btn
    ><CreateTemplateDialog v-if="GET_OpenCreateTemplateDialog" />
    <EditTemplateDialog v-if="GET_OpenEditTemplateDialog" />
    <DeleteTemplateDialog v-if="GET_OpenDeleteTemplateDialog"
  /></v-container>
  <v-container v-else>
    <div class="full-page-loader primary-background">
      <v-progress-circular
        :size="100"
        :width="7"
        color="purple"
        indeterminate
      />
      <span class="text-h4 purple--text">Loading </span>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ErrorMessageAlert from "../MessageToUser/ErrorMessageAlert.vue";
import CreateTemplateDialog from "../TemplateDialog/CreateTemplateDialog.vue";
import EditTemplateDialog from "../TemplateDialog/EditTemplateDialog.vue";
import DeleteTemplateDialog from "../TemplateDialog/DeleteTemplateDialog.vue";
export default {
  components: {
    ErrorMessageAlert,
    CreateTemplateDialog,
    EditTemplateDialog,
    DeleteTemplateDialog,
  },
  data() {
    return {
      alert: false,
    };
  },
  computed: {
    ...mapGetters("settingStore", [
      "GET_SettingsList",
      "GET_TemplateType",
      "GET_SelectedTemplatesForTemplateType",
      "GET_NewOrUpdatedTemplate",
      "GET_OpenCreateTemplateDialog",
      "GET_OpenEditTemplateDialog",
      "GET_OpenDeleteTemplateDialog",
      "GET_ErrorMessage",
    ]),

    location() {
      const shortName = this.$route.params.shortName;
      try {
        const list = [...this.GET_SettingsList];
        const findTemplatesList = list.find(
          (item) => item.shortName === shortName
        );
        return findTemplatesList;
      } catch {
        return "";
      }
    },
  },

  async created() {
    if (this.GET_TemplateType === null) {
      this.SET_TemplateType(this.$route.params.shortName);
    }
    await this.FETCH_TemplatesForSelectedTemplateType(this.GET_TemplateType);

    if (
      this.GET_ErrorMessage &&
      Object.keys(this.GET_ErrorMessage).length > 0
    ) {
      this.alert = true;
    }
  },

  watch: {
    async GET_NewOrUpdatedTemplate(newValue) {
      if (newValue === true) {
        await this.FETCH_TemplatesForSelectedTemplateType(
          this.GET_TemplateType
        );
        this.SET_NewOrUpdatedTemplate(false);
      }
    },
  },

  methods: {
    ...mapMutations("settingStore", [
      "SET_TemplateType",
      "SET_NewOrUpdatedTemplate",
      "SET_OpenCreateTemplateDialog",
      "SET_OpenEditTemplateDialog",
      "SET_OpenDeleteTemplateDialog",
      "SET_SelectedTemplatesForTemplateType",
    ]),
    ...mapActions("settingStore", [
      "FETCH_TemplatesForSelectedTemplateType",
      "FETCH_SelectedTemplateWithCategory",
    ]),

    async fetchSelectedTemplateWithCategory(itemId) {
      await this.FETCH_SelectedTemplateWithCategory({
        id: itemId,
        templateType: this.GET_TemplateType,
      });
    },

    async openSelectedTemplate(item) {
      const itemId = item.id;
      await this.fetchSelectedTemplateWithCategory(itemId);

      const templateName = item.name
        .trim()
        .replace(/[-_\s]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ""));

      this.$router.push(
        this.location.link +
          this.location.shortName +
          "/" +
          templateName +
          "/" +
          item.id
      );
    },

    async openEditDialog(item) {
      const itemId = item.id;
      await this.fetchSelectedTemplateWithCategory(itemId);
      this.SET_OpenEditTemplateDialog(true);
    },

    async openDeleteDialog(item) {
      const itemId = item.id;
      await this.fetchSelectedTemplateWithCategory(itemId);
      this.SET_OpenDeleteTemplateDialog(true);
    },

    goBack() {
      this.SET_SelectedTemplatesForTemplateType({});
      this.SET_TemplateType(null);
      return this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
.full-page-loader {
  text-shadow: 1px 1px 4px rgba(25, 25, 25, 0.5);
  position: absolute;
  left: 50%;
  top: 50%;
  -moz-transform: translate(-50%, -50%); /* Firefox */
  -ms-transform: translate(-50%, -50%); /* IE 9 */
  -webkit-transform: translate(-50%, -50%); /* Safari and Chrome*/
  -o-transform: translate(-50%, -50%); /* Opera */
  transform: translate(-50%, -50%);

  /* optional size in px or %: */
  width: 100px;
  height: 100px;
}
.header {
  margin-bottom: 16px;
  padding-bottom: 30px;
  text-transform: lowercase;
}
.header::first-letter {
  text-transform: uppercase;
}
.card-title-container {
  justify-content: space-between;
  padding: 16px 25px;
  padding-top: 45px;
  padding-bottom: 0px;
}
.card-sub-title {
  padding: 0;
  font-size: 20px;
  font-weight: 300;
}
.list-group {
  padding: 30px 12px !important;
  font-size: 20px;
  font-weight: 300;
}
.list-item {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bolder;
}
.title {
  font-size: 20px;
}
.edit-icon {
  padding-right: 20px;
}
.back-btn {
  margin: 46px 0px;
  font-weight: 300;
}
.go-back-icon {
  padding-right: 10px;
}
.error-msg {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>