import { graphFetch, sendEmail } from "../api/msGraphApi";
import { addCompleted } from "./firestoreRoutes";
import store from "../store";

// send Mail

export const simpleMail = async (
  emailSubject,
  emailHtmlString,
  toAddress,
 // toCcAddress
) => {
  //Create the object to send to the Graph API

  const email = {
    message: {
      subject: emailSubject,
      body: {
        contentType: "html",
        content: emailHtmlString,
      },
      toRecipients: 
        toAddress.map((toAddress) => {
          return {
            emailAddress: {
              address: toAddress,
            },
          };
        }),
      
      /*toCcAddress: [
        toCcAddress.map((toCcAddress) => {
          return {
            emailAddress: {
              address: toCcAddress,
            },
          };
        }),
      ],*/
    },
    saveToSentItems: "false",
  };

  //Call the MS Graph API to send an email
  const response = await sendEmail({
    method: "POST",
    body: email && JSON.stringify(email),
  });

  //If status = 202 then the email was sent successfully
  //If status = 400 then the email was not sent successfully
  if (response.status === 202) {
    return;
  } else {
    throw new Error("Error sending email");
  }
};

export const mail = async (personId, doneTask, template, manager, mailText) => {
  try {
    const person = await store.getters["onboardingPeople/getPerson"](
      personId.id
    );

    if (doneTask.id) {
      const completed = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
      const task = person.tasks.find((x) => x.id === doneTask.id);

      const body = {
        personId: person.id,
        taskId: task.id,
        date: completed,
      };
      addCompleted("people/on/task/completed", body);

      store.commit("onboardingPeople/setCompletedTask", {
        id: person.id,
        updatedTask: { id: task.id, completed: completed },
      });
    } else {
      const completed = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
      const task = person.tasks.find((x) => x.text === doneTask.text);

      const body = {
        personId: person.id,
        taskId: task.id,
        date: completed,
      };
      addCompleted("people/on/task/completed", body);

      store.commit("onboardingPeople/setCompletedTask", {
        id: person.id,
        updatedTask: { id: task.id, completed: completed },
      });
    }

    let URL;

    if (mailText.bottom.to === "person") {
      URL = `${process.env.VUE_APP_DEV_URL}onprogress/${person.id}`;
    } else if (mailText.bottom.to === "azure") {
      URL = `${process.env.VUE_APP_DEV_URL}azure/${person.id}`;
    } else if (mailText.bottom.to === "person-info") {
      URL = `${process.env.VUE_APP_DEV_URL}person/${person.id}`;
    } else {
      const task = await person.tasks.find(
        (x) => x.text === mailText.bottom.doneTask
      );
      URL = `${process.env.VUE_APP_DEV_URL}fulfilled/${person.id}/${task.id}`;
    }

    let emailTemplate = template.replace("{URL}", URL);

    const emails = [
      {
        message: {
          subject: `Onboarding ${person.FirstName} ${person.LastName} `,
          body: {
            contentType: "html",
            content: emailTemplate,
          },
          toRecipients: [
            {
              emailAddress: {
                address: manager.email,
              },
            },
          ],
        },
        saveToSentItems: "false",
      },
    ];

    let response;
    for (const email of emails) {
      response = await graphFetch({
        method: "POST",
        body: email && JSON.stringify(email),
      });

      if (response.statusText !== "Accepted") {
        return response.status;
      }
    }
    return response;
  } catch (error) {
    return error;
  }
};
