<template>
  <v-container>
    <h2 class="header font-weight-thin text-h2">Preview the mail template</h2>
    <h4 font-weight-thin text-h4>You cant edit in this view</h4>
    <v-card class="card">
      <v-card-title class="card-title-container"
        ><v-card-subtitle class="card-sub-title">{{
          GET_SelectedMailTemplate.name
        }}</v-card-subtitle></v-card-title
      >
      <EmailEditor
        ref="emailEditor"
        v-on:load="editorLoaded"
        class="editor"
        :options="{ projectId: '207608' }"
      />
      <v-spacer></v-spacer>
    </v-card>
    <v-btn class="back-btn" depressed color="primary" @click="goBack()"
      ><v-icon class="go-back-icon">mdi-keyboard-backspace</v-icon>Go
      back</v-btn
    >
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { EmailEditor } from "vue-email-editor";
export default {
  components: { EmailEditor },
  data() {
    return {};
  },

  computed: {
    ...mapGetters("settingStore", ["GET_SelectedMailTemplate"]),
  },

  methods: {
    ...mapMutations("settingStore", ["SET_SelectedMailTemplate"]),

    editorLoaded() {
      const task = JSON.parse(JSON.stringify(this.GET_SelectedMailTemplate));

      if (Object.keys(task.templateData).length > 0) {
        this.$refs.emailEditor.editor.loadDesign(task.templateData);
        this.$refs.emailEditor.editor.showPreview(task.templateData);
      } else {
        console.log("something is wrong");
      }
    },
    goBack() {
      this.SET_SelectedMailTemplate({});
      return this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.header {
  margin-bottom: 16px;
  padding-bottom: 30px;
  text-transform: lowercase;
}
.header::first-letter {
  text-transform: uppercase;
}
.card-title-container {
  justify-content: space-between;
  padding: 16px 25px;
  padding-top: 25px;
  padding-bottom: 10px;
}
.card-sub-title {
  padding: 0;
  font-size: 20px;
  font-weight: 300;
}
.card ::v-deep iframe {
  position: relative !important;
  height: 70vh !important;
  min-width: 100% !important;
}
.editor {
  width: 100% !important;
}
.back-btn {
  margin: 46px 0px;
  font-weight: 300;
}
.go-back-icon {
  padding-right: 10px;
}
</style>