import {getApiData} from '../api/api.js';


const layoutStore = {
  namespaced: true,
  state: {
    onBoardingAndOffBoardingPeople: {},
    layoutErrorMessage: {},
  },
  getters: {
    GET_OnBoardingAndOffBoardingPeople: (state) => state.onBoardingAndOffBoardingPeople,
    GET_LayoutErrorMessage : (state) => state.layoutErrorMessage
  },
  mutations: {
    SET_OnBoardingAndOffBoardingPeople(state, updatedOnBoardingAndOffBoardingPeople){
      state.onBoardingAndOffBoardingPeople = updatedOnBoardingAndOffBoardingPeople
    },
    SET_LayoutErrorMessage(state, updateLayoutErrorMessage){
      state.layoutErrorMessage = updateLayoutErrorMessage;
    }
  },
  actions: {
    async FETCH_People(context){
      const json = false;
      try {
        const response = await getApiData(`people`, json);
          if(response.status === 200){
            const result = await response.json();
            await context.commit('SET_OnBoardingAndOffBoardingPeople', result)
          }  else {
            await context.commit('SET_LayoutErrorMessage', {alert: true, message : 'Failed to fetch on- and offboarding lists, please refesh the page. If it doesnt work contact the administrator. '})
          }
      } catch(err){
        console.log('error')
        return
      }
    }
  }
}

export default layoutStore;