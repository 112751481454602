<template>
  <v-form ref="form" v-model="valid">
    <v-container>
      <v-row>
        <v-col
          ><v-select
            v-model="person"
            :items="GET_OnBoardingAndOffBoardingPeople.onBoarding"
            :menu-props="{ top: false, offsetY: true }"
            item-text="name"
            label="OnBoarding person*"
            return-object
            attach
          ></v-select
        ></v-col>
        <v-col>
          <v-select
            v-model="selectedTemplate"
            @focus="$event.target.click()"
            :items="GET_SelectedTemplatesForTemplateType"
            :menu-props="{ top: false, offsetY: true }"
            item-text="name"
            label="OffBoarding list*"
            return-object
            attach
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="mt-2">
          <v-menu
            ref="startMenu"
            v-model="startMenu"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :close-on-content-click="false"
            :return-value.sync="date"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                label="Last workday"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="[(v) => !!v || 'Date is required']"
                required
              />
            </template>
            <v-date-picker
              v-model="startDate"
              @input="startMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="actions">
      <ErrorMessageAlert
        v-if="alert"
        v-model="alert"
        :text="GET_OnOffBoardingErrorMessage.message"
      />
      <v-spacer></v-spacer>
      <v-btn
        depressed
        color="primary"
        class="btn"
        :disabled="
          Object.keys(person).length === 0 ||
          Object.keys(selectedTemplate).length === 0
        "
        @click="saveOffBoardingProcessForOnBoardingPerson()"
      >
        Start Process
      </v-btn>
    </v-card-actions>
  </v-form>
</template>


<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ErrorMessageAlert from "../../components/MessageToUser/ErrorMessageAlert.vue";
export default {
  components: {
    ErrorMessageAlert,
  },
  data() {
    return {
      valid: true,
      alert: false,
      person: {},
      selectedTemplate: {},
      startMenu: false,
      date: null,
      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },
  computed: {
    ...mapGetters("layoutStore", ["GET_OnBoardingAndOffBoardingPeople"]),
    ...mapGetters("settingStore", ["GET_SelectedTemplatesForTemplateType"]),
    ...mapGetters("onBoardingStore", [
      "GET_SelectedPersonWithTasks",
      "GET_OnOffBoardingErrorMessage",
      "GET_SelectedPersonId",
    ]),
  },

  watch: {
    alert(newValue) {
      if (newValue === false) {
        this.SET_OnOffBoardingErrorMessage({});
      }
    },
  },
  methods: {
    ...mapMutations("onBoardingStore", ["SET_OnOffBoardingErrorMessage"]),
    ...mapActions("onBoardingStore", [
      "FETCH_OnBoardingAndOfBoardingPersonWithTasks",
      "POST_CreatedOnAndOffBoardingPerson",
    ]),

    async getOnBoardingPerson() {
      await this.FETCH_OnBoardingAndOfBoardingPersonWithTasks({
        type: "onBoarding",
        id: this.person.id,
      });
    },

    mergeInfoForOffboardingPerson() {
      const keys = Object.keys(this.person.jobInfo);
      if (!keys.includes("workEmail")) {
        this.person.jobInfo.workEmail =
          this.GET_SelectedPersonWithTasks.jobInfo.workEmail;
      }
      if (!keys.includes("azureId")) {
        this.person.jobInfo.azureId =
          this.GET_SelectedPersonWithTasks.jobInfo.azureId;
      }
      if (!keys.includes("manager")) {
        this.person.jobInfo.manager =
          this.GET_SelectedPersonWithTasks.jobInfo.manager;
      }

      const person = {
        id: this.person.id,
        userName: this.person.name,
        firstName: this.GET_SelectedPersonWithTasks.firstName,
        lastName: this.GET_SelectedPersonWithTasks.lastName,
        equipment: this.GET_SelectedPersonWithTasks.equipment,
        jobInfo: this.person.jobInfo,
        privateInfo: this.GET_SelectedPersonWithTasks.privateInfo,
        templateId: this.selectedTemplate.id,
        templateName: this.selectedTemplate.name,
        startDate: this.startDate,
      };
      this.person = person;
    },

    async saveOffBoardingProcessForOnBoardingPerson() {
      this.alert = false;
      await this.getOnBoardingPerson();
      this.mergeInfoForOffboardingPerson();

      const type = this.$route.name;

      await this.POST_CreatedOnAndOffBoardingPerson({
        type: type,
        person: this.person,
      });
      if (
        this.GET_OnOffBoardingErrorMessage &&
        Object.keys(this.GET_OnOffBoardingErrorMessage).length > 0
      ) {
        this.alert = true;

        return;
      } else {
        // await this.DELETE_PersonWithTask({ type: "onBoarding", id: person.id });

        this.$router.push({
          path: `offProgress/${this.GET_SelectedPersonId}`,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.actions {
  padding: 16px 0px;
}
.btn {
  font-weight: 300;
}
