<template>
  <v-container>
    <h2 class="header font-weight-thin text-h2">OnBoarding</h2>
    <p>
      Here you add all the necessary information about the person you want to
      start the Onboarding process with
    </p>

    <v-expansion-panels multiple>
      <v-expansion-panel v-for="(item, i) in infoType" :key="i">
        <v-expansion-panel-header class="header-name">{{
          item.title
        }}</v-expansion-panel-header
        ><v-expansion-panel-content v-if="item.type === 'privat'"
          ><v-form ref="form1" v-model="valid1">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="person.privateInfo.email"
                    :rules="[rules.required, rules.email]"
                    label="Private E-mail*"
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="person.privateInfo.phone"
                    :rules="[rules.required, rules.phone]"
                    label="Private Phone number*"
                    required
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-spacer></v-spacer>
              <small>*indicates required field</small>
            </v-container>
          </v-form>
        </v-expansion-panel-content>
        <v-expansion-panel-content v-else>
          <v-form ref="form2" v-model="valid2" lazy-validation
            ><v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="person.firstName"
                    :rules="[rules.required]"
                    label="First name*"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="person.lastName"
                    :rules="[rules.required]"
                    label="Last name*"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="person.jobInfo.title"
                    label="Job title*"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="geshdoMail"
                    :items="employeesInfo"
                    :menu-props="{ top: false, offsetY: true }"
                    @focus="$event.target.click()"
                    item-text="mail"
                    return-object
                    label="GESHDO e-mail"
                    attach
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="person.jobInfo.office"
                    @focus="$event.target.click()"
                    :items="offices"
                    :menu-props="{ top: false, offsetY: true }"
                    label="Office*"
                    required
                    attach
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="person.jobInfo.manager"
                    @focus="$event.target.click()"
                    :items="managers"
                    :menu-props="{ top: false, offsetY: true }"
                    label="Manager*"
                    required
                    attach
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="selectedTemplate"
                    @focus="$event.target.click()"
                    :items="GET_SelectedTemplatesForTemplateType"
                    :menu-props="{ top: false, offsetY: true }"
                    item-text="name"
                    label="OnBoarding Template*"
                    return-object
                    attach
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" class="mt-2">
                  <v-menu
                    ref="startMenu"
                    v-model="startMenu"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="person.startDate"
                        label="Start Date*"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[(v) => !!v || 'Date is required']"
                        required
                      />
                    </template>
                    <v-date-picker
                      v-model="person.startDate"
                      @input="startMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-combobox
                    v-model="person.equipment"
                    :items="equipment"
                    :menu-props="{ top: false, offsetY: true }"
                    label="Select borrowed equipment"
                    attach
                    multiple
                    chips
                    ><template
                      v-slot:selection="{ attrs, item, parent, selected }"
                      ><v-chip
                        :key="JSON.stringify(item)"
                        :input-value="selected"
                        v-bind="attrs"
                      >
                        <span
                          >{{ item }}
                          <v-icon small @click="parent.selectItem(item)"
                            >$delete</v-icon
                          ></span
                        >
                      </v-chip></template
                    >
                  </v-combobox>
                </v-col>
              </v-row>
            </v-container>
            <v-spacer></v-spacer>
            <small>*indicates required field</small></v-form
          ></v-expansion-panel-content
        >
      </v-expansion-panel>
    </v-expansion-panels>
    <v-spacer></v-spacer>

    <v-card-actions class="actions">
      <ErrorMessageAlert
        v-if="alert"
        v-model="alert"
        :text="GET_OnOffBoardingErrorMessage.message"
      />
      <v-spacer></v-spacer>
      <v-btn depressed color="primary" class="btn" @click="reset()">
        Clear form
      </v-btn>
      <v-btn
        depressed
        color="primary"
        class="btn"
        :disabled="isDisabled"
        @click="saveOnBoardingPerson()"
      >
        Start Process
      </v-btn>
    </v-card-actions>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getAllCompanyUsers } from "../../services/msGraphActions";
import ErrorMessageAlert from "../../components/MessageToUser/ErrorMessageAlert.vue";

export default {
  components: { ErrorMessageAlert },
  data() {
    return {
      alert: false,
      valid1: true,
      valid2: true,
      startMenu: false,
      date: null,
      employees: null,
      selectedTemplate: {},
      geshdoMail: {},
      infoType: [
        { title: "Private Info", type: "privat" },
        { title: "Job Info", type: "job" },
      ],
      offices: ["Malmö", "Göteborg", "Remote"],
      owners: [],
      equipment: [
        "MacBook",
        "Dell pc",
        "Keyboard",
        "Headphone",
        "Ipad",
        "Mobile phone",
      ],
      person: {
        firstName: "",
        lastName: "",
        startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        templateId: "",
        equipment: [],
        privateInfo: {
          email: "",
          phone: "",
        },
        jobInfo: {
          office: "",
          manager: "",
          title: "",
          workEmail: "",
          azureId: "",
        },
      },
      rules: {
        required: (value) => !!value || "Required",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        phone: (value) => {
          if (!value) {
            return true;
          } else {
            const pattern =
              /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

            return (
              pattern.test(value.replace(/ /g, "")) || "Invalid phone number."
            );
          }
        },
      },
    };
  },
  computed: {
    ...mapGetters("settingStore", ["GET_SelectedTemplatesForTemplateType"]),
    ...mapGetters("onBoardingStore", [
      "GET_SelectedPersonId",
      "GET_OnOffBoardingErrorMessage",
    ]),

    isDisabled() {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const phone = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
      return (
        !pattern.test(this.person.privateInfo.email) ||
        !phone.test(this.person.privateInfo.phone.replace(/ /g, "")) ||
        this.person.firstName == "" ||
        this.person.lastName == "" ||
        this.person.startDate == "" ||
        this.person.jobInfo.office == "" ||
        this.person.jobInfo.manager == "" ||
        this.person.jobInfo.title == ""
      );
    },

    managers() {
      try {
        const information = this.employees?.value.map((value) => {
          return value.displayName;
        });
        return information;
      } catch {
        return [];
      }
    },

    employeesInfo() {
      try {
        const information = this.employees?.value.map((value) => {
          return (value = {
            mail: value.mail,
            id: value.id,
            displayName: value.displayName,
          });
        });

        information.unshift({ mail: "", id: "", displayName: "" });
        return information;
      } catch {
        return [];
      }
    },
  },
  async created() {
    await this.FETCH_TemplatesForSelectedTemplateType(this.$route.name);
    this.employees = await getAllCompanyUsers();
  },
  watch: {
    alert(newValue) {
      if (newValue === false) {
        this.SET_OnOffBoardingErrorMessage({});
      }
    },
  },

  methods: {
    ...mapMutations("onBoardingStore", ["SET_OnOffBoardingErrorMessage"]),
    ...mapActions("settingStore", ["FETCH_TemplatesForSelectedTemplateType"]),
    ...mapActions("onBoardingStore", ["POST_CreatedOnAndOffBoardingPerson"]),

    reset() {
      this.$refs.form1[0].reset();
      this.$refs.form2[0].reset();
    },
    async saveOnBoardingPerson() {
      this.alert = false;
      const type = this.$route.name;
      (this.person.templateId = this.selectedTemplate.id),
        (this.person.jobInfo.workEmail = this.geshdoMail.mail),
        (this.person.jobInfo.azureId = this.geshdoMail.id),
        await this.POST_CreatedOnAndOffBoardingPerson({
          type: type,
          person: this.person,
        });
      const personId = this.GET_SelectedPersonId;
      if (
        this.GET_OnOffBoardingErrorMessage &&
        Object.keys(this.GET_OnOffBoardingErrorMessage).length > 0
      ) {
        this.alert = true;

        return;
      } else {
        this.$router.push({
          path: `onProgress/${personId}`,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  margin-bottom: 16px;
  padding-bottom: 25px;
}
.header-name {
  font-size: 20px;
  font-weight: bold;
}
.actions {
  padding: 16px 0px;
}
.btn {
  font-weight: 300;
}
</style>