<template>
  <v-container class="pl-0 pr-0 pt-10">
    <ErrorMessageAlert
      v-if="alert"
      v-model="alert"
      :text="GET_OnOffBoardingErrorMessage.message"
    />
    <ErrorMessageAlert
      v-if="emailAlert"
      v-model="emailAlert"
      :text="GET_OnOffBoardingErrorMessage.message"
    />
    <v-data-table
      v-if="GET_EditTask"
      :headers="headers"
      :items="sortItems"
      :hide-default-footer="true"
      item-key="name"
      group-by="position"
      class="elevation-1"
      disable-pagination
      single-select
      style="cursor: pointer"
      @click:row="rowClick"
    >
      <template v-slot:[`group.header`]="{ group, headers, isOpen }">
        <td :colspan="headers.length">
          <v-btn
            class="icon-btn"
            x-small
            icon
            :ref="group"
            :data-open="isOpen"
            disable-items-per-page
          >
            <v-icon v-if="isOpen">mdi-minus</v-icon>
          </v-btn>
          <span class="mx-5 font-weight-bold">{{ sortList[group] }}</span>
        </td>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon v-if="item.emailTemplateId && item.emailTemplateId != ''"
          >mdi-email-arrow-right-outline</v-icon
        >
      </template>

      <template v-slot:[`item.completed`]="{ item }">
        <v-simple-checkbox
          :value="item.completed"
          :ripple="false"
          :disabled="Boolean(item.completed)"
          readonly
        ></v-simple-checkbox>
      </template>
    </v-data-table>
    <v-data-table
      v-else
      :headers="headers"
      :items="sortItems"
      :hide-default-footer="true"
      item-key="name"
      group-by="position"
      class="elevation-1"
      disable-pagination
      style="cursor: pointer"
      @click:row="showSelectedItem"
    >
      <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen }">
        <td :colspan="headers.length">
          <v-btn
            class="icon-btn"
            @click="toggle"
            x-small
            icon
            :ref="group"
            :data-open="isOpen"
            disable-items-per-page
          >
            <v-icon v-if="isOpen">mdi-minus</v-icon>
            <v-icon v-else>mdi-plus</v-icon>
          </v-btn>
          <span class="mx-5 font-weight-bold">{{ sortList[group] }}</span>
        </td>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          v-if="item.emailTemplateId && item.emailTemplateId != ''"
          @click="openEmailTemplate(item)"
          >mdi-email-arrow-right-outline</v-icon
        >
      </template>
      <template v-slot:[`item.completed`]="{ item }">
        <v-simple-checkbox
          v-model="item.completed"
          :ripple="false"
          @click="updateTaskStatus(item)"
        ></v-simple-checkbox>
      </template>
    </v-data-table>
    <EditOnBoardingPersonTaskDialog v-if="GET_OpenSelectedOnBoardingTask" />
    <ShowSelectedTaskDialog v-if="GET_OpenShowSelectedOnBoardingTask" />
    <PersonCopyMailTemplate v-if="GET_OpenSelectedMailTemplateFromPersonTask" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ErrorMessageAlert from "../MessageToUser/ErrorMessageAlert.vue";
import EditOnBoardingPersonTaskDialog from "../OnProgressDialog/EditOnBoardingPersonTaskDialog.vue";
import ShowSelectedTaskDialog from "../OnProgressDialog/ShowSelectedTaskDialog.vue";
import PersonCopyMailTemplate from "../OnProgressDialog/PersonCopyMailTemplate.vue";

export default {
  components: {
    ErrorMessageAlert,
    EditOnBoardingPersonTaskDialog,
    ShowSelectedTaskDialog,
    PersonCopyMailTemplate,
  },
  data() {
    return {
      sortList: [
        "One month before",
        "One week before",
        "1st day",
        "Office onboarding",
        "Tech Excellence onboarding",
      ],
      emailTemplateIsSelected: false,
      alert: false,
      emailAlert: false,
    };
  },

  computed: {
    ...mapGetters("onBoardingStore", [
      "GET_EditTask",
      "GET_SelectedPersonWithTasks",
      "GET_OpenSelectedOnBoardingTask",
      "GET_SelectedOnBoardingTask",
      "GET_OpenShowSelectedOnBoardingTask",
      "GET_OpenSelectedMailTemplateFromPersonTask",
      "GET_OnOffBoardingErrorMessage",
    ]),

    headers() {
      return [
        { text: "Completed", value: "completed" },
        { text: "Title", value: "text" },
        { text: "Completed by", value: "completedBy" },
        { text: "Completed on", value: "completedOn" },
        { text: "Assigned to", value: "assignedTo" },
        { text: "Actions", value: "actions" },
      ];
    },

    sortItems() {
      if (!this.GET_SelectedPersonWithTasks) return;
      const sortItems = [...this.GET_SelectedPersonWithTasks.tasks].sort(
        (a, b) => (a.typeIndex > b.typeIndex ? 1 : -1)
      );
      return sortItems.map((item) => ({
        ...item,
        position: this.sortList.indexOf(item.type),
      }));
    },
  },
  watch: {
    alert(newValue) {
      if (newValue === false) {
        this.SET_OnOffBoardingErrorMessage({});
      }
    },
    emailAlert(newValue) {
      if (newValue === false) {
        this.SET_OnOffBoardingErrorMessage({});
      }
    },
  },

  methods: {
    ...mapMutations("onBoardingStore", [
      "SET_OpenSelectedMailTemplateFromPersonTask",
      "SET_SelectedOnBoardingTask",
      "SET_OpenSelectedOnBoardingTask",
      "SET_SelectedTaskWithMail",
      "SET_OpenShowSelectedOnBoardingTask",
      "SET_OnOffBoardingErrorMessage",
    ]),
    ...mapActions("onBoardingStore", [
      "FETCH_SelectedMailTemplateFromPersonTask",
      "EDIT_TaskStatus",
    ]),

    showSelectedItem(item) {
      if (this.emailTemplateIsSelected) return;
      this.SET_SelectedOnBoardingTask(item);
      this.SET_OpenShowSelectedOnBoardingTask(true);
    },

    rowClick: function (item, row) {
      if (item.completed === false) {
        console.log(row);
        //row.select(true);
        this.SET_SelectedOnBoardingTask(item);
        this.SET_OpenSelectedOnBoardingTask(true);
      }
    },
    async updateTaskStatus(item) {
      this.alert = false;
      const infoToSend = {
        type: "onBoarding",
        taskId: item.id,
        personId: this.GET_SelectedPersonWithTasks.id,
      };
      const updatedTask = { completed: item.completed };
      await this.EDIT_TaskStatus({ infoToSend, updatedTask });
      if (this.GET_OnOffBoardingErrorMessage.alert === true) {
        this.alert = true;
      }
    },

    async openEmailTemplate(item) {
      this.emailAlert = false;
      this.emailTemplateIsSelected = true;
      await this.FETCH_SelectedMailTemplateFromPersonTask({
        emailId: item.emailTemplateId,
        type: "onBoarding",
        personId: this.GET_SelectedPersonWithTasks.id,
      });
      if (this.GET_OnOffBoardingErrorMessage.alert === true) {
        this.emailAlert = true;
        return;
      }
      this.SET_SelectedTaskWithMail({
        personId: this.GET_SelectedPersonWithTasks.id,
        type: "onBoarding",
        taskId: item.id,
      });
      this.SET_OpenSelectedMailTemplateFromPersonTask(true);
      this.emailTemplateIsSelected = false;
    },
  },
};
</script>

<style scoped lang="scss">
.icon-btn {
  float: right;
}
tr.v-data-table__selected {
  background: #7d92f5 !important;
}
</style>