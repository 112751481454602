<template>
  <v-container>
    <h2 class="header font-weight-thin text-h2">Settings</h2>
    <v-card
      ><v-list
        ><v-list-item-group class="list-group"
          ><v-list-item
            v-for="(item, i) in GET_SettingsList"
            :key="i"
            class="item"
            ><v-icon class="icon">{{ item.icon }}</v-icon
            ><v-list-item-content
              ><v-list-item-title
                class="title"
                link
                @click="
                  () => {
                    selectedTemplates(item);
                  }
                "
                >{{ item.text }}</v-list-item-title
              >
            </v-list-item-content></v-list-item
          ></v-list-item-group
        ></v-list
      ></v-card
    >
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {};
  },

  computed: {
    ...mapGetters("settingStore", ["GET_SettingsList", "GET_TemplateType"]),
  },
  methods: {
    ...mapMutations("settingStore", [
      "SET_OpenTemplateDialog",
      "SET_TemplateType",
    ]),

    async selectedTemplates(item) {
      this.SET_TemplateType(item.shortName);

      if (item.shortName === "ManagePeople" || item.shortName === "Mail") {
        this.$router.push(item.link);
      } else {
        this.$router.push(item.link + item.shortName);
      }
    },
  },
};
</script>

<style scoped>
.header {
  margin-bottom: 16px;
  padding-bottom: 30px;
}
.list-group {
  padding-top: 30px;
  padding-bottom: 30px;
}
.icon {
  padding-right: 15px;
}
.item {
  padding-top: 15px;
  padding-bottom: 15px;
}

.title {
  font-size: 20px;
  font-weight: 300;
}
</style>