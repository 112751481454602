<template>
  <v-container>
    <h2 class="header font-weight-thin text-h2">{{ GET_TemplateType }}</h2>
    <v-card elevation="1">
      <div v-if="alert">
        <ErrorMessageAlert
          v-if="alert"
          v-model="alert"
          :text="GET_ErrorMessage.message"
        />
      </div>
      <v-expansion-panels v-else accordion class="list-group"
        ><v-expansion-panel
          class="list-item"
          v-for="(type, i) in typeList"
          :key="i"
          ><v-expansion-panel-header class="header-name"
            >{{ type }}
          </v-expansion-panel-header>
          <v-expansion-panel-content class="first-content">
            <v-list>
              <v-list-item
                v-for="(person, i) in GET_OnBoardingAndOffBoardingPeople[type]"
                :key="i"
                ><v-list-item-content>
                  <v-list-item-title
                    >{{ person.name }}
                  </v-list-item-title> </v-list-item-content
                ><v-icon @click="openDeleteDialog(person, type)"
                  >mdi-delete</v-icon
                ></v-list-item
              >
            </v-list></v-expansion-panel-content
          ></v-expansion-panel
        >
        <v-expansion-panel class="list-item">
          <v-expansion-panel-header class="header-name"
            >Managers</v-expansion-panel-header
          ><v-expansion-panel-content
            ><v-list
              ><v-list-item v-for="(person, i) in managers.value" :key="i"
                ><v-list-item-content class="first-content"
                  ><v-list-item-title>{{
                    person.displayName
                  }}</v-list-item-title></v-list-item-content
                ></v-list-item
              ></v-list
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="list-item">
          <v-expansion-panel-header class="header-name"
            >Admin</v-expansion-panel-header
          ><v-expansion-panel-content
            ><v-list
              ><v-list-item v-for="(person, i) in admins.value" :key="i"
                ><v-list-item-content class="first-content"
                  ><v-list-item-title>{{
                    person.displayName
                  }}</v-list-item-title></v-list-item-content
                ></v-list-item
              ></v-list
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <v-btn class="back-btn" depressed color="primary" @click="goBack()"
      ><v-icon class="go-back-icon">mdi-keyboard-backspace</v-icon>Go
      back</v-btn
    >
    <DeletePersonDialog v-if="GET_OpenDeletePersonDialog" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import ErrorMessageAlert from "../MessageToUser/ErrorMessageAlert.vue";
import DeletePersonDialog from "../DeletePersonDialog/DeletePersonDialog.vue";
import { getAllGroupMembers } from "../../services/msGraphActions";
export default {
  components: { DeletePersonDialog, ErrorMessageAlert },
  data() {
    return {
      alert: false,
      typeList: ["onBoarding", "offBoarding"],
      onboarding: [],
      offboarding: [],
      people: [],
      managers: [],
      admins: [],
    };
  },
  computed: {
    ...mapGetters("settingStore", [
      "GET_TemplateType",
      "GET_OnBoardingAndOffBoardingPeople",
      "GET_OpenDeletePersonDialog",
      "GET_NewOrUpdatedPersonDelete",
      "GET_ErrorMessage",
    ]),
  },
  async created() {
    if (this.GET_TemplateType === null) {
      this.SET_TemplateType(this.$route.name);
    }
    await this.FETCH_OnBoardingAndOffBoardingPeople();
    if (
      this.GET_ErrorMessage &&
      Object.keys(this.GET_ErrorMessage).length > 0
    ) {
      this.alert = true;
    }
    await this.getManagersAndAdmin();
  },
  watch: {
    async GET_NewOrUpdatedPersonDelete(newValue) {
      if (newValue === true) {
        await this.FETCH_OnBoardingAndOffBoardingPeople();
        this.SET_NewOrUpdatedPeopleDelete(false);
      }
    },
  },
  methods: {
    ...mapMutations("settingStore", [
      "SET_TemplateType",
      "SET_SelectedPerson",
      "SET_OpenDeletePersonDialog",
      "SET_NewOrUpdatedPeopleDelete",
    ]),
    ...mapActions("settingStore", ["FETCH_OnBoardingAndOffBoardingPeople"]),

    async getManagersAndAdmin() {
      this.managers = await getAllGroupMembers(
        process.env.VUE_APP_AZURE_MANAGER_GROUP
      );

      this.managers.value.sort((a, b) => {
        return a.displayName.toLowerCase() > b.displayName.toLowerCase()
          ? 1
          : -1;
      });

      this.admins = await getAllGroupMembers(
        process.env.VUE_APP_AZURE_AMIN_GROUP
      );

      this.admins.value.sort((a, b) => {
        return a.displayName.toLowerCase() > b.displayName.toLowerCase()
          ? 1
          : -1;
      });
    },

    openDeleteDialog(item, type) {
      this.SET_SelectedPerson({ item: item, type: type });
      this.SET_OpenDeletePersonDialog(true);
    },

    goBack() {
      this.SET_TemplateType(null);
      return this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.header {
  margin-bottom: 16px;
  padding-bottom: 30px;
}

.card-title-container {
  justify-content: space-between;
  padding: 16px 25px;
  padding-top: 45px;
  padding-bottom: 0px;
}
.list-group {
  padding: 30px 12px !important;
  font-size: 20px;
  font-weight: 300;
}
.list-item {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bolder;
}
.card-sub-title {
  padding: 0;
  font-size: 20px;
  font-weight: 300;
}

.sub-title-container {
  justify-content: space-between;
  padding: 16px 25px;
}
.sub-title {
  font-size: 16px;
  font-weight: bold;
}
.v-expansion-panel::before {
  box-shadow: none;
}

.header-name {
  font-size: 20px;
  font-weight: bold;
}
.header-name::first-letter {
  text-transform: uppercase;
}

.back-btn {
  margin: 46px 0px;
  font-weight: 300;
}
.go-back-icon {
  padding-right: 10px;
}
.error-msg {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>