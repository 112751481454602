<template>
  <v-container v-if="Object.keys(GET_SelectedTemplateWithCategory).length != 0">
    <h2 class="header font-weight-thin text-h2">
      {{ GET_SelectedTemplateWithCategory.name }}
    </h2>
    <v-card class="card"
      ><v-card-title class="card-title-container"
        ><v-card-subtitle class="card-sub-title"
          >Create a new task</v-card-subtitle
        ><v-icon @click="SET_OpenCreateTaskDialog(true)"
          >mdi-note-plus</v-icon
        ></v-card-title
      ><v-expansion-panels accordion class="list-group"
        ><v-expansion-panel
          class="list-item"
          v-for="(type, i) in GET_SelectedTemplateWithCategory.types"
          :key="i"
          ><v-expansion-panel-header class="header-name">{{
            type
          }}</v-expansion-panel-header
          ><v-expansion-panel-content class="first-content"
            ><v-card elevation="1">
              <v-expansion-panels accordion
                ><v-expansion-panel
                  v-for="(task, i) in GET_SelectedTemplateWithCategory.tasks"
                  :key="i"
                  ><v-expansion-panel-header
                    class="header-template"
                    v-if="task.type === type"
                    >{{ task.text }} </v-expansion-panel-header
                  ><v-expansion-panel-content v-if="task.type === type"
                    ><v-form class="form"
                      ><v-row
                        ><v-col cols="12" sm="6"
                          ><v-text-field
                            :value="task.createdBy"
                            label="Created by"
                            readonly
                            :disabled="task.createdBy === ''"
                          ></v-text-field></v-col
                        ><v-col cols="12" sm="6"
                          ><v-text-field
                            :value="task.assignedTo"
                            label="Assigned to"
                            readonly
                            :disabled="task.assignedTo === ''"
                          ></v-text-field></v-col
                      ></v-row>
                      <v-row
                        ><v-col cols="12" sm="6" class="last-text-field"
                          ><v-text-field
                            :value="task.emailTemplateName"
                            label="Email action"
                            readonly
                            :disabled="task.emailTemplateName === ''"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6"
                          ><v-textarea
                            :value="task.description"
                            outlined
                            auto-grow
                            label="Description"
                            rows="3"
                            :disabled="!task.description"
                          ></v-textarea
                        ></v-col>
                      </v-row>
                      <v-row justify="end"
                        ><v-col cols="12" sm="6" class="action-col"
                          ><v-icon
                            class="edit-icon"
                            @click="openEditTaskDialog(task)"
                            >mdi-pencil</v-icon
                          ><v-icon @click="openDeleteTaskDialog(task)"
                            >mdi-delete</v-icon
                          ></v-col
                        ></v-row
                      ></v-form
                    ></v-expansion-panel-content
                  >
                </v-expansion-panel></v-expansion-panels
              ></v-card
            >
          </v-expansion-panel-content></v-expansion-panel
        ></v-expansion-panels
      ></v-card
    ><v-btn class="back-btn" depressed color="primary" @click="goBack()"
      ><v-icon class="go-back-icon">mdi-keyboard-backspace</v-icon>Go
      back</v-btn
    >
    <CreateTaskDialog v-if="GET_OpenCreateTaskDialog" />
    <EditTaskDialog v-if="GET_OpenEditTaskDialog" />
    <DeleteTaskDialog v-if="GET_OpenDeleteTaskDialog" />
  </v-container>
  <v-container v-else>
    <div class="full-page-loader primary-background">
      <v-progress-circular
        :size="100"
        :width="7"
        color="purple"
        indeterminate
      />
      <span class="text-h4 purple--text">Loading </span>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import CreateTaskDialog from "../../TaskDialog/CreateTaskDialog.vue";
import EditTaskDialog from "../../TaskDialog/EditTaskDialog.vue";
import DeleteTaskDialog from "../../TaskDialog/DeleteTaskDialog.vue";

export default {
  components: { CreateTaskDialog, EditTaskDialog, DeleteTaskDialog },
  data() {
    return {};
  },

  computed: {
    ...mapGetters("settingStore", [
      "GET_SelectedTemplateWithCategory",
      "GET_OpenCreateTaskDialog",
      "GET_OpenEditTaskDialog",
      "GET_OpenDeleteTaskDialog",
      "GET_NewOrUpdatedTask",
    ]),
  },
  async created() {
    if (Object.keys(this.GET_SelectedTemplateWithCategory).length === 0) {
      const template = {
        templateType: this.$route.params.shortName,
        id: this.$route.params.id,
      };
      await this.FETCH_SelectedTemplateWithCategory(template);
      this.SET_SelectedTask({});
    }
  },

  watch: {
    async GET_NewOrUpdatedTask(newValue) {
      if (newValue === true) {
        await this.FETCH_SelectedTemplateWithCategory({
          templateType: this.GET_SelectedTemplateWithCategory.category,
          id: this.GET_SelectedTemplateWithCategory.id,
        });
        this.SET_NewOrUpdatedTask(false);
      }
    },
  },

  methods: {
    ...mapMutations("settingStore", [
      "SET_OpenCreateTaskDialog",
      "SET_OpenEditTaskDialog",
      "SET_OpenDeleteTaskDialog",
      "SET_NewOrUpdatedTask",
      "SET_SelectedTask",
    ]),

    ...mapActions("settingStore", ["FETCH_SelectedTemplateWithCategory"]),

    openEditTaskDialog(task) {
      const selectedTask = JSON.parse(JSON.stringify(task));
      this.SET_SelectedTask(selectedTask);
      this.SET_OpenEditTaskDialog(true);
    },

    openDeleteTaskDialog(task) {
      const selectedTask = JSON.parse(JSON.stringify(task));
      this.SET_SelectedTask(selectedTask);
      this.SET_OpenDeleteTaskDialog(true);
    },

    goBack() {
      this.SET_SelectedTask([]);
      return this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang='scss'>
.full-page-loader {
  text-shadow: 1px 1px 4px rgba(25, 25, 25, 0.5);
  position: absolute;
  left: 50%;
  top: 50%;
  -moz-transform: translate(-50%, -50%); /* Firefox */
  -ms-transform: translate(-50%, -50%); /* IE 9 */
  -webkit-transform: translate(-50%, -50%); /* Safari and Chrome*/
  -o-transform: translate(-50%, -50%); /* Opera */
  transform: translate(-50%, -50%);

  /* optional size in px or %: */
  width: 100px;
  height: 100px;
}
.header {
  margin-bottom: 16px;
  padding-bottom: 30px;
}
.card-title-container {
  justify-content: space-between;
  padding: 16px 25px;
  padding-top: 45px;
  padding-bottom: 0px;
}
.list-group {
  padding: 30px 12px !important;
  font-size: 20px;
  font-weight: 300;
}
.list-item {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bolder;
}
.card-sub-title {
  padding: 0;
  font-size: 20px;
  font-weight: 300;
}

.sub-title-container {
  justify-content: space-between;
  padding: 16px 25px;
}
.sub-title {
  font-size: 16px;
  font-weight: bold;
}
.v-expansion-panel::before {
  box-shadow: none;
}
.first-content ::v-deep .v-expansion-panel-content__wrap {
  padding: 0px;
  max-width: 100%;
}
.header-name {
  font-size: 16px;
  font-weight: bold;
}
.header-template {
  padding-left: 45px;
  padding-right: 45px;
}
.form {
  padding: 16px 45px 10px;
}
.last-text-field {
  margin-top: 29px;
}
.action-col {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
}
.edit-icon {
  padding-right: 25px;
}
.back-btn {
  margin: 46px 0px;
  font-weight: 300;
}
.go-back-icon {
  padding-right: 10px;
}
</style>