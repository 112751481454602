<template>
  <v-row justify="center"
    ><v-dialog
      v-model="GET_OpenCreateTemplateDialog"
      persistent
      max-width="600px"
      ><v-card
        ><v-card-title class="header font-weight-thin text-h4"
          >Create a new template</v-card-title
        ><v-form ref="form" v-model="valid" :lazy-validation="lazy" class="form"
          ><v-text-field
            v-model="name"
            label="Add a template name"
            :rules="nameRules"
            class="text-field"
          ></v-text-field
          ><ErrorMessageAlert
            v-if="alert"
            v-model="alert"
            :text="GET_ErrorMessage.message"
          />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="action-btn"
              depressed
              color="primary"
              @click="reset()"
            >
              Cancel
            </v-btn>
            <v-btn
              class="action-btn"
              depressed
              color="primary"
              :disabled="name == null || name.length < 2"
              @click="createTemplate()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form></v-card
      ></v-dialog
    ></v-row
  >
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ErrorMessageAlert from "../MessageToUser/ErrorMessageAlert.vue";
export default {
  components: { ErrorMessageAlert },
  data() {
    return {
      valid: true,
      lazy: true,
      name: "",
      nameRules: [(v) => !!v || "Name is required"],
      alert: false,
    };
  },
  computed: {
    ...mapGetters("settingStore", [
      "GET_OpenCreateTemplateDialog",
      "GET_TemplateType",
      "GET_ErrorMessage",
    ]),
  },
  watch: {
    alert(newValue) {
      if (newValue === false) {
        this.SET_ErrorMessage({});
      }
    },
  },

  methods: {
    ...mapMutations("settingStore", [
      "SET_OpenCreateTemplateDialog",
      "SET_ErrorMessage",
    ]),
    ...mapActions("settingStore", ["POST_NewTemplate"]),

    reset() {
      this.$refs.form.reset();
      this.SET_OpenCreateTemplateDialog(false);
    },

    async createTemplate() {
      this.alert = false;
      this.$refs.form.validate();
      const templateNameWithTemplateType = {
        category: this.GET_TemplateType,
        name: this.name,
      };
      await this.POST_NewTemplate(templateNameWithTemplateType);

      if (
        this.GET_ErrorMessage &&
        Object.keys(this.GET_ErrorMessage).length > 0
      ) {
        this.alert = true;
        return;
      }

      this.SET_OpenCreateTemplateDialog(false);
      this.$refs.form.reset();
    },
  },
};
</script>

<style scoped lang='scss'>
.header {
  justify-content: center;
}
.form {
  padding: 16px 24px 10px;
}
.text-field {
  font-weight: 300;
}
.action-btn {
  font-weight: 300;
}
</style>