import Vue from "vue";
import Router from "vue-router";
import store from "../store";
//import Home from "../views/Home.vue";
import Home from "../views/HomeView/Home.vue";
import OnBoarding from "../views/OnBoarding/OnBoarding.vue";
import OnProgress from "../components/OnProgress/OnProgress.vue";
import OffBoarding from '../views/OffBoarding/OffBoarding.vue';
import OffProgress from '../components/OffProgress/OffProgress.vue';
import Settings from "../views/SettingsViews/Settings.vue";
import SettingsOnOffBoardingTemplates from '../components/SettingsOnOffBoardingTemplates/SettingsOnOffBoardringTemplates.vue'
import SelectedTemplateWithCategories from '../components/SettingsOnOffBoardingTemplates/SelectedTemplateWithCategories/SelectedTemplateWithCategories.vue'

//import SettingsCategory from "../components/SettingsTemplates/SettingsTemplates.vue"
//import TemplateCategories from "../components/TemplateCategories/TemplateCategories.vue"
import MailTemplates from "../components/MailTemplates/MailTemplates.vue"
import SelectedTemplate from "../components/MailTemplates/SelectedTemplate.vue"
import ManagePeople from "../components/ManagePeople/ManagePeople.vue"

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
      meta: {
        requireRoles: ["Admin", "Manager"]
      }
    },
    {
      path:"/settings",
      name: "Settings",
      component: Settings,
      meta: { 
        requireRoles: ["Admin"] 
      }
    },
    {
      path: "/settings/ManagePeople",
      name: "ManagePeople", 
      component: ManagePeople,
      meta: { 
        requireRoles: ["Admin"] 
      }
    },
    {
      path: "/settings/Mail",
      name: "Mail", 
      component: MailTemplates,
      meta: { 
        requireRoles: ["Admin"] 
      }
    },
    {
      path: "/settings/Mail/:name/:id",
      name: "SelectedTemplate", 
      component: SelectedTemplate,
      meta: { 
        requireRoles: ["Admin"] 
      }
    },

    {
      path: "/settings/:shortName",
      name: "SettingsOnOffBoardingTemplates",
      component: SettingsOnOffBoardingTemplates,
      meta: { 
        requireRoles: ["Admin"] 
      }
    }, 
    {
      path: "/settings/:shortName/:name/:id",
      name: "SelectedTemplateWithCategories", 
      component: SelectedTemplateWithCategories,
      meta: { 
        requireRoles: ["Admin"] 
      }
    },
    {
      path: "/onBoarding",
      name: "onBoarding",
      component: OnBoarding,
      meta: { 
        requireRoles: ["Admin", "Manager"] 
      },
    },
    {
      path: "/onProgress/:id",
      name: "onProgress",
      component: OnProgress,
      meta: { 
        requireRoles: ["Admin", "Manager"] 
      },
      
    },
    {
      path: "/offBoarding",
      name: "offBoarding",
      component: OffBoarding,
      meta: { 
        requireRoles: ["Admin", "Manager"] 
      },
      
    },
    {
      path: "/offProgress/:id",
      name: "offProgress",
      component: OffProgress,
      meta: { 
        requireRoles: ["Admin", "Manager"] 
      },
      
    },
    {
      path: "/azure/:personId/",
      name: "azure",
      props: true,
      component: () =>
        import(/* webpackChunkName: "Azure"*/ "../views/Azure.vue"),
      meta: { requireRoles: ["Admin"] },
    },
  ]

})

router.beforeEach(async (to, from, next) => {
  const { requireRoles } = to.meta;
  let currentUser = store.state.user;

  if (requireRoles) {
    if (currentUser === undefined) {
      currentUser = await store.dispatch("signIn");
    }
    if (currentUser) {
      // check if route is restricted by role

      if (
        requireRoles.length &&
        !requireRoles.some((e) => currentUser.role.includes(e))
      ) {
        return alert("You don't have access to this page");
      }
      next();
    }
  } else next();
});

export default router;

