<template>
  <v-row>
    <v-dialog v-model="GET_OpenEditMailTemplateDialog" persistent
      ><v-card class="card">
        <v-form ref="form" v-model="valid" :lazy-validation="lazy" class="form">
          <v-row class="text-fields">
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="selected.name"
                :rules="textRules"
                label="Title*"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="selected.subject"
                :rules="textRules"
                label="Subject*"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-combobox
                v-model="selected.toAddress"
                :items="employeesInfo"
                :rules="emailRules"
                item-text="mail"
                label="Mail to"
                :persistent-hint="true"
                hint="You can either select or write an email adress"
                return-object
                attach
              ></v-combobox>
            </v-col>
          </v-row>

          <v-spacer></v-spacer>
          <EmailEditor
            ref="emailEditor"
            v-on:load="editorLoaded"
            :options="{ projectId: '207608' }"
          />

          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <small class="indicate">*indicates required field</small>
          <v-card-actions>
            <v-spacer></v-spacer>
            <div></div>
            <v-btn class="btn" depressed color="primary" @click="reset()">
              Cancel
            </v-btn>
            <v-btn
              class="btn"
              depressed
              color="primary"
              :disabled="isDisabled"
              @click="saveDesign()"
            >
              SaveDesign
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { EmailEditor } from "vue-email-editor";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { getAllCompanyUsers } from "../../services/msGraphActions.js";
export default {
  components: { EmailEditor },
  data() {
    return {
      valid: true,
      lazy: true,
      textRules: [(v) => !!v || "Title is required"],
      employees: null,
      selected: {
        name: "",
        subject: "",
        toAddress: "",
        templateData: {},
      },
    };
  },
  computed: {
    ...mapGetters("settingStore", [
      "GET_OpenEditMailTemplateDialog",
      "GET_SelectedMailTemplate",
    ]),
    employeesInfo() {
      try {
        const information = this.employees?.value.map((value) => {
          return (value = value.mail);
        });
        information.unshift("Candidate private email");
        information.unshift("");

        return information;
      } catch {
        return [];
      }
    },

    emailRules() {
      return [
        (v) => {
          if (v === "") return true;
          if (v === "Candidate private email") return true;
          if (/.+@.+/.test(v)) return true;

          return "E-mail must be valid";
        },
      ];
    },
    isDisabled() {
      return (
        this.selected.name == "" ||
        this.selected.subject == "" ||
        (this.selected.toAddress !== "" &&
          this.selected.toAddress !== "Candidate private email" &&
          !/.+@.+/.test(this.selected.toAddress))
      );
    },
  },
  async created() {
    this.employees = await getAllCompanyUsers();
  },
  methods: {
    ...mapMutations("settingStore", [
      "SET_OpenEditMailTemplateDialog",
      "SET_SelectedMailTemplate",
    ]),
    ...mapActions("settingStore", ["EDIT_SelectedMailTemplate"]),

    editorLoaded() {
      const task = JSON.parse(JSON.stringify(this.GET_SelectedMailTemplate));
      this.selected = task;

      if (Object.keys(this.selected.templateData).length > 0) {
        this.$refs.emailEditor.editor.loadDesign(this.selected.templateData);
      } else {
        console.log("something is wrong");
      }
    },
    reset() {
      this.$refs.form.reset();
      this.SET_SelectedMailTemplate({});
      this.$refs.emailEditor.editor.loadBlank({
        backgroundColor: "#e7e7e7",
      });
      this.SET_OpenEditMailTemplateDialog(false);
    },
    async saveDesign() {
      this.$refs.form.validate();
      await this.$refs.emailEditor.editor.saveDesign((design) => {
        this.selected.templateData = design;
        this.EDIT_SelectedMailTemplate(this.selected);
        this.SET_OpenEditMailTemplateDialog(false);
        this.$refs.emailEditor.editor.loadBlank({
          backgroundColor: "#e7e7e7",
        });
      });
    },
  },
};
</script>

<style scoped>
.card ::v-deep iframe {
  height: 70vh !important;
}
.form {
  padding: 16px 24px 10px;
}
.text-fields {
  padding: 30px 25px 10px;
}
.indicate {
  margin-left: 24px;
}
.btn {
  font-weight: 300;
}
</style>