<template>
  <v-container v-if="GET_TemplateType != null">
    <h2 class="header font-weight-thin text-h2">{{ GET_TemplateType }}</h2>
    <v-card>
      <v-card-title class="card-title-container"
        ><v-card-subtitle class="card-sub-title"
          >Create a new Mail template</v-card-subtitle
        ><v-icon @click="openCreateTemplateDialog()"
          >mdi-note-plus</v-icon
        ></v-card-title
      ><v-list v-if="alert">
        <v-list-item class="error-msg"
          ><ErrorMessageAlert
            v-if="alert"
            v-model="alert"
            :text="GET_ErrorMessage.message"
        /></v-list-item>
      </v-list>
      <v-list v-else>
        <v-list-item-group class="list-group">
          <v-list-item
            v-for="(item, i) in GET_MailTemplates"
            :key="i"
            class="list-item"
          >
            <v-list-item-content>
              <v-list-item-title
                class="title"
                link
                @click="openSelectedMailTemplate(item)"
                >{{ item.name }}</v-list-item-title
              >
            </v-list-item-content>
            <v-icon class="edit-icon" @click="openEditDialog(item)"
              >mdi-pencil</v-icon
            ><v-icon @click="openDeleteDialog(item)">mdi-delete</v-icon>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <!--<div class="empty-div" v-else></div>-->
    </v-card>
    <v-btn class="back-btn" depressed color="primary" @click="goBack()"
      ><v-icon class="go-back-icon">mdi-keyboard-backspace</v-icon>Go
      back</v-btn
    >
    <CreateMailTemplateDialog v-if="GET_OpenCreateMailTemplateDialog" />
    <EditMailTemplateDialog v-if="GET_OpenEditMailTemplateDialog" />
    <DeleteMailTemplateDialog v-if="GET_OpenDeleteMailTemplateDialog" />
  </v-container>
  <v-container v-else>
    <div class="full-page-loader primary-background">
      <v-progress-circular
        :size="100"
        :width="7"
        color="purple"
        indeterminate
      />
      <span class="text-h4 purple--text">Loading </span>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ErrorMessageAlert from "../MessageToUser/ErrorMessageAlert.vue";
import CreateMailTemplateDialog from "../MailTemplateDialog/CreateMailTemplateDialog.vue";
import EditMailTemplateDialog from "../MailTemplateDialog/EditMailTemplateDialog.vue";
import DeleteMailTemplateDialog from "../MailTemplateDialog/DeleteMailTemplateDialog.vue";
export default {
  components: {
    ErrorMessageAlert,
    CreateMailTemplateDialog,
    EditMailTemplateDialog,
    DeleteMailTemplateDialog,
  },
  data() {
    return {
      list: null,
      alert: false,
    };
  },
  computed: {
    ...mapGetters("settingStore", [
      "GET_TemplateType",
      "GET_MailTemplates",
      "GET_OpenCreateMailTemplateDialog",
      "GET_OpenEditMailTemplateDialog",
      "GET_OpenDeleteMailTemplateDialog",
      "GET_NewOrUpdatedMailTemplate",
      "GET_ErrorMessage",
    ]),
  },
  async created() {
    if (this.GET_TemplateType === null) {
      this.SET_TemplateType(this.$route.name);
    }
    await this.FETCH_MailTemplates();
    if (
      this.GET_ErrorMessage &&
      Object.keys(this.GET_ErrorMessage).length > 0
    ) {
      this.alert = true;
    }
  },
  watch: {
    async GET_NewOrUpdatedMailTemplate(newValue) {
      if (newValue === true) {
        await this.FETCH_MailTemplates();
        this.SET_NewOrUpdatedMailTemplate(false);
      }
    },
  },

  methods: {
    ...mapMutations("settingStore", [
      "SET_OpenCreateMailTemplateDialog",
      "SET_TemplateType",
      "SET_NewOrUpdatedMailTemplate",
      "SET_OpenEditMailTemplateDialog",
      "SET_OpenDeleteMailTemplateDialog",
    ]),
    ...mapActions("settingStore", [
      "FETCH_MailTemplates",
      "FETCH_SelectedMailTemplate",
      "DELETE_SelectedMailTemplate",
    ]),

    openCreateTemplateDialog() {
      this.SET_OpenCreateMailTemplateDialog(true);
      return;
    },

    async openEditDialog(item) {
      await this.FETCH_SelectedMailTemplate(item.id);
      this.SET_OpenEditMailTemplateDialog(true);
      return;
    },
    async openDeleteDialog(item) {
      this.FETCH_SelectedMailTemplate(item.id);
      this.SET_OpenDeleteMailTemplateDialog(true);
    },
    async openSelectedMailTemplate(item) {
      await this.FETCH_SelectedMailTemplate(item.id);
      this.$router.push(this.$route.name + "/" + item.name + "/" + item.id);
    },
    goBack() {
      this.SET_TemplateType(null);
      return this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.full-page-loader {
  text-shadow: 1px 1px 4px rgba(25, 25, 25, 0.5);
  position: absolute;
  left: 50%;
  top: 50%;
  -moz-transform: translate(-50%, -50%); /* Firefox */
  -ms-transform: translate(-50%, -50%); /* IE 9 */
  -webkit-transform: translate(-50%, -50%); /* Safari and Chrome*/
  -o-transform: translate(-50%, -50%); /* Opera */
  transform: translate(-50%, -50%);

  /* optional size in px or %: */
  width: 100px;
  height: 100px;
}
.header {
  margin-bottom: 16px;
  padding-bottom: 30px;
  text-transform: lowercase;
}
.header::first-letter {
  text-transform: uppercase;
}
.card-title-container {
  justify-content: space-between;
  padding: 16px 25px;
  padding-top: 45px;
  padding-bottom: 0px;
}
.card-sub-title {
  padding: 0;
  font-size: 20px;
  font-weight: 300;
}
.list-group {
  padding: 30px 12px !important;
  font-size: 20px;
  font-weight: 300;
}
.list-item {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bolder;
}
.title {
  font-size: 20px;
}
.edit-icon {
  padding-right: 20px;
}
.empty-div {
  padding: 25px;
}
.back-btn {
  margin: 46px 0px;
  font-weight: 300;
}
.go-back-icon {
  padding-right: 10px;
}
.error-msg {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>