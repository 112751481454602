<template>
  <v-form>
    <v-container
      ><h4 class="mb-5">Job Info</h4>
      <v-row
        ><v-col cols="12" sm="6">
          <v-text-field
            label="First name"
            :value="GET_SelectedPersonWithTasks.firstName"
            readonly
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Last Name"
            :value="GET_SelectedPersonWithTasks.lastName"
            readonly
          >
          </v-text-field> </v-col></v-row
      ><v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            :value="GET_SelectedPersonWithTasks.jobInfo.title"
            label="Job title"
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            :value="GET_SelectedPersonWithTasks.jobInfo.workEmail"
            label="GESHDO mail"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            :value="GET_SelectedPersonWithTasks.jobInfo.office"
            label="Office"
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            :value="GET_SelectedPersonWithTasks.jobInfo.manager"
            label="Manager"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            :value="GET_SelectedPersonWithTasks.templateName"
            label="OnBoarding Template"
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            :value="GET_SelectedPersonWithTasks.startDate"
            label="Start Date"
            prepend-inner-icon="mdi-calendar"
            readonly
        /></v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          v-if="
            GET_SelectedPersonWithTasks.equipment &&
            GET_SelectedPersonWithTasks.equipment != ''
          "
        >
          <v-select
            v-model="GET_SelectedPersonWithTasks.equipment"
            :items="GET_SelectedPersonWithTasks.equipment"
            chips
            multiple
            label="Borrowed equipment"
          ></v-select>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      ><h4 class="mb-5">Private Info</h4>
      <v-row
        ><v-col cols="12" sm="6">
          <v-text-field
            label="Private E-mail"
            :value="GET_SelectedPersonWithTasks.privateInfo.email"
            readonly
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Private Phone number"
            :value="GET_SelectedPersonWithTasks.privateInfo.phone"
            readonly
          >
          </v-text-field> </v-col></v-row
    ></v-container>
    <v-spacer></v-spacer>

    <v-card-actions class="actions">
      <v-spacer></v-spacer>
      <v-btn depressed color="primary" class="btn" @click="editPersonInfo()">
        Edit
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("onBoardingStore", ["GET_SelectedPersonWithTasks"]),
  },
  methods: {
    ...mapMutations("onBoardingStore", ["SET_OpenEditSelectedPersonInfo"]),

    async editPersonInfo() {
      this.SET_OpenEditSelectedPersonInfo(true);
    },
  },
};
</script>

<style scoped lang="scss">
.last-row {
  justify-content: end;
}
.btn {
  margin: 46px 0px;
  font-weight: 300;
}
</style>