<template>
  <v-container>
    <h2 class="header font-weight-thin text-h2">OffBoarding</h2>
    <p>
      Here are all the necessary information about the person you want to start
      the Offboarding process with
    </p>
    <v-expansion-panels multiple>
      <v-expansion-panel>
        <v-expansion-panel-header class="header-name">
          Select a user from the onboarding list
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <OffBoardingPersonFromOnBoardingList />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header class="header-name">
          Add a new offBoarding person that do not exist in onBoarding list
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <OffBoardingPerson />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { getAllCompanyUsers } from "../../services/msGraphActions";
import OffBoardingPerson from "./OffBoardingPerson.vue";
import OffBoardingPersonFromOnBoardingList from "./OffBoardingPersonFromOnboardingList.vue";

export default {
  components: {
    OffBoardingPerson,
    OffBoardingPersonFromOnBoardingList,
  },
  data() {
    return {};
  },

  async created() {
    await this.FETCH_TemplatesForSelectedTemplateType(this.$route.name);
    await this.FETCH_People();
    this.SET_Employees(await getAllCompanyUsers());
  },

  methods: {
    ...mapMutations("onBoardingStore", ["SET_Employees"]),
    ...mapActions("layoutStore", ["FETCH_People"]),
    ...mapActions("settingStore", ["FETCH_TemplatesForSelectedTemplateType"]),
  },
};
</script>

<style scoped lang="scss">
.header {
  margin-bottom: 16px;
  padding-bottom: 25px;
}
.header-name {
  font-size: 20px;
  font-weight: bold;
}
</style>