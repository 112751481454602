<template>
  <v-navigation-drawer app clipped permanent>
    <v-list>
      <v-subheader class="text-overline">Logged in as: </v-subheader>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h5 pb-4 profileImageContainer">
            <img
              v-if="userPicture"
              v-bind:src="userPicture"
              class="profileImage"
            />
            <img
              v-else
              src="../../assets/No_image_available.svg.png"
              class="profileImage"
            />
            <div class="userFullName">
              {{ user.name }}
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list>
      <v-list-item to="/">
        <v-list-item-icon>
          <v-icon>mdi-home-city</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item to="/onBoarding">
        <v-list-item-content>
          <v-list-item-title>Onboarding</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon>mdi-plus</v-icon>
        </v-list-item-icon>
      </v-list-item>

      <div v-if="alert">
        <ErrorMessageAlert
          v-model="alert"
          :text="GET_LayoutErrorMessage.message"
        />
      </div>

      <v-list-item
        v-else
        v-for="(person, i) in GET_OnBoardingAndOffBoardingPeople.onBoarding"
        :key="i"
        :to="`/onProgress/${person.id}`"
        @click="getSelectedPerson('onBoarding', person.id)"
        ><v-list-item-icon>
          <v-icon>mdi-account-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ person.name }}</v-list-item-title>
        </v-list-item-content></v-list-item
      >

      <v-list-item to="/offBoarding">
        <v-list-item-content>
          <v-list-item-title>OffBoarding</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon>mdi-minus</v-icon>
        </v-list-item-icon>
      </v-list-item>

      <div v-if="alert">
        <ErrorMessageAlert
          v-model="alert"
          :text="GET_LayoutErrorMessage.message"
        />
      </div>

      <v-list-item
        v-else
        v-for="(
          person, index
        ) in GET_OnBoardingAndOffBoardingPeople.offBoarding"
        :key="'person' + index"
        :to="`/offProgress/${person.id}`"
        @click="getSelectedPerson('offBoarding', person.id)"
        ><v-list-item-icon>
          <v-icon>mdi-account-minus</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ person.name }}</v-list-item-title>
        </v-list-item-content></v-list-item
      >
      <v-divider></v-divider>

      <v-list-item :to="{ name: 'Settings' }">
        <v-list-item-icon>
          <v-icon>mdi-cog</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-action>
          <v-switch v-model="$vuetify.theme.dark"></v-switch>
        </v-list-item-action>
        <v-list-item-title>{{
          $vuetify.theme.dark ? "Light theme" : "Dark theme"
        }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getProfilePicture } from "../../services/msGraphActions.js";
import ErrorMessageAlert from "../MessageToUser/ErrorMessageAlert.vue";

export default {
  components: { ErrorMessageAlert },
  data() {
    return {
      userPicture: "",
      alert: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("layoutStore", [
      "GET_OnBoardingAndOffBoardingPeople",
      "GET_LayoutErrorMessage",
    ]),
    ...mapGetters("settingStore", ["GET_NewOrUpdatedPersonDelete"]),
    ...mapGetters("onBoardingStore", ["GET_NewOrUpdatedPerson"]),
  },

  watch: {
    async GET_NewOrUpdatedPerson(newValue) {
      if (newValue === true) {
        await this.FETCH_People();
        this.SET_NewOrUpdatedPerson(false);
      }
    },
    async GET_NewOrUpdatedPersonDelete(newValue) {
      if (newValue === true) {
        await this.FETCH_People();
        this.SET_NewOrUpdatedPeopleDelete(false);
      }
    },
  },

  async mounted() {
    this.userPicture = await getProfilePicture(this.user.azureId);
    await this.FETCH_People();
    if (
      this.GET_LayoutErrorMessage &&
      Object.keys(this.GET_LayoutErrorMessage).length > 0
    ) {
      this.alert = true;
    }
  },

  methods: {
    ...mapMutations("onBoardingStore", ["SET_NewOrUpdatedPerson"]),
    ...mapMutations("settingStore", ["SET_NewOrUpdatedPeopleDelete"]),
    ...mapActions("layoutStore", ["FETCH_People"]),

    ...mapActions("onBoardingStore", [
      "FETCH_OnBoardingAndOfBoardingPersonWithTasks",
    ]),

    async getSelectedPerson(type, id) {
      await this.FETCH_OnBoardingAndOfBoardingPersonWithTasks({
        id: id,
        type: type,
      });
    },
  },
};
</script>

<style scoped>
.profileImage {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}
.userFullName {
  margin: auto auto auto 2px;
}
.profileImageContainer {
  text-align: center;
}
</style>