<template>
  <v-form ref="form" v-model="valid"
    ><v-container>
      <v-row
        ><v-col cols="12" sm="6">
          <v-select
            v-model="onBoardingPerson"
            :items="selectedEmployees"
            :menu-props="{ top: false, offsetY: true }"
            item-text="displayName"
            label="Select a person from the list"
            return-object
            attach
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            v-model="selectedTemplate"
            @focus="$event.target.click()"
            :items="GET_SelectedTemplatesForTemplateType"
            :menu-props="{ top: false, offsetY: true }"
            item-text="name"
            label="OffBoarding Template*"
            return-object
            attach
          ></v-select> </v-col></v-row
      ><v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="selectedPerson.privateInfo.email"
            :rules="[rules.required, rules.email]"
            label="Private E-mail*"
            required
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="selectedPerson.privateInfo.phone"
            :rules="[rules.required, rules.phone]"
            label="Private Phone number*"
            required
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="selectedPerson.firstName"
            :rules="[rules.required]"
            label="First name*"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="selectedPerson.lastName"
            :rules="[rules.required]"
            label="Last name*"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="selectedPerson.jobInfo.title"
            label="Job title*"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="selectedPerson.jobInfo.workEmail"
            @focus="$event.target.click()"
            label="GESHDO e-mail"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-select
            v-model="selectedPerson.jobInfo.office"
            @focus="$event.target.click()"
            :items="offices"
            :menu-props="{ top: false, offsetY: true }"
            label="Office"
            required
            attach
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            v-model="selectedPerson.jobInfo.manager"
            @focus="$event.target.click()"
            :items="managers"
            :menu-props="{ top: false, offsetY: true }"
            label="Manager"
            required
            attach
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="mt-2">
          <v-menu
            ref="startMenu"
            v-model="startMenu"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :close-on-content-click="false"
            :return-value.sync="date"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="selectedPerson.startDate"
                label="Last workday"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="[(v) => !!v || 'Date is required']"
                required
              />
            </template>
            <v-date-picker
              v-model="selectedPerson.startDate"
              @input="startMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6">
          <v-combobox
            v-model="selectedPerson.equipment"
            :items="equipment"
            :menu-props="{ top: false, offsetY: true }"
            label="Select borrowed equipment"
            attach
            multiple
            chips
            ><template v-slot:selection="{ attrs, item, parent, selected }"
              ><v-chip
                :key="JSON.stringify(item)"
                :input-value="selected"
                v-bind="attrs"
              >
                <span
                  >{{ item }}
                  <v-icon small @click="parent.selectItem(item)"
                    >$delete</v-icon
                  ></span
                >
              </v-chip></template
            >
          </v-combobox>
        </v-col>
      </v-row></v-container
    ><v-card-actions class="actions">
      <ErrorMessageAlert
        v-if="alert"
        v-model="alert"
        :text="GET_OnOffBoardingErrorMessage.message"
      />
      <v-spacer></v-spacer>
      <v-btn depressed color="primary" class="btn" @click="reset()">
        Clear form
      </v-btn>
      <v-btn
        depressed
        color="primary"
        class="btn"
        :disabled="isDisabled"
        @click="saveOffBoardingProcess()"
      >
        Start Process
      </v-btn>
    </v-card-actions></v-form
  >
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ErrorMessageAlert from "../../components/MessageToUser/ErrorMessageAlert.vue";
export default {
  components: {
    ErrorMessageAlert,
  },
  data() {
    return {
      alert: false,
      valid: true,
      startMenu: false,
      date: null,
      employees: null,
      onBoardingPerson: {},
      selectedTemplate: {},
      offices: ["Malmö", "Göteborg", "Remote"],
      equipment: [
        "MacBook",
        "Dell pc",
        "Keyboard",
        "Headphone",
        "Ipad",
        "Mobile phone",
      ],
      selectedPerson: {
        firstName: "",
        lastName: "",
        startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        templateId: "",
        equipment: [],
        privateInfo: {
          email: "",
          phone: "",
        },
        jobInfo: {
          office: "",
          manager: "",
        },
      },
      rules: {
        required: (value) => !!value || "Required",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        phone: (value) => {
          if (!value) {
            return true;
          } else {
            const pattern =
              /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

            return (
              pattern.test(value.replace(/ /g, "")) || "Invalid phone number."
            );
          }
        },
      },
    };
  },
  computed: {
    ...mapGetters("settingStore", ["GET_SelectedTemplatesForTemplateType"]),
    ...mapGetters("onBoardingStore", ["GET_Employees", "GET_SelectedPersonId"]),

    selectedEmployees() {
      try {
        const existingEmployees = this.GET_Employees?.value.map((value) => {
          return (value = {
            displayName: value.displayName,
            jobInfo: {
              title: value.jobTitle,
              workEmail: value.mail,
              azureId: value.id,
            },
          });
        });
        return existingEmployees;
      } catch {
        return [];
      }
    },
    managers() {
      try {
        const information = this.GET_Employees?.value.map((value) => {
          return value.displayName;
        });
        return information;
      } catch {
        return [];
      }
    },

    isDisabled() {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const phone = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
      return (
        !pattern.test(this.selectedPerson.privateInfo.email) ||
        !phone.test(this.selectedPerson.privateInfo.phone.replace(/ /g, "")) ||
        Object.keys(this.onBoardingPerson).length <= 0 ||
        Object.keys(this.selectedTemplate).length <= 0 ||
        this.selectedPerson.firstName == "" ||
        this.selectedPerson.lastName == "" ||
        this.selectedPerson.jobInfo.title == "" ||
        this.selectedPerson.jobInfo.workEmail == "" ||
        this.selectedPerson.jobInfo.office == ""
      );
    },
  },
  watch: {
    alert(newValue) {
      if (newValue === false) {
        this.SET_OnOffBoardingErrorMessage({});
      }
    },

    onBoardingPerson(newValue) {
      if (newValue) {
        this.selectedPerson = Object.assign(this.selectedPerson, newValue);
        let firstAndLastName = this.selectedPerson.displayName.split(" ");
        this.selectedPerson.firstName = firstAndLastName[0];
        this.selectedPerson.lastName = firstAndLastName[1];
      }
    },
  },
  methods: {
    ...mapMutations("onBoardingStore", ["SET_OnOffBoardingErrorMessage"]),
    ...mapActions("onBoardingStore", ["POST_CreatedOnAndOffBoardingPerson"]),

    reset() {
      this.$refs.form.reset();
    },

    async saveOffBoardingProcess() {
      this.alert = false;
      const type = this.$route.name;
      this.selectedPerson.templateId = this.selectedTemplate.id;

      await this.POST_CreatedOnAndOffBoardingPerson({
        type: type,
        person: this.selectedPerson,
      });

      const personId = this.GET_SelectedPersonId;
      if (
        this.GET_OnOffBoardingErrorMessage &&
        Object.keys(this.GET_OnOffBoardingErrorMessage).length > 0
      ) {
        this.alert = true;

        return;
      } else {
        this.$router.push({
          path: `offProgress/${personId}`,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.actions {
  padding: 16px 0px;
}
.btn {
  font-weight: 300;
}
