<template>
  <v-app>
    <template v-if="authenticated">
      <sidebar />
      <app-bar />
      <v-main>
        <v-container fluid>
          <router-view :key="$route.fullPath" />
        </v-container>
      </v-main>
    </template>
    <div class="full-page-loader primary-background" v-else>
      <v-progress-circular
        :size="100"
        :width="7"
        color="purple"
        indeterminate
      />
      <span class="text-h4 purple--text">Authenticating</span>
    </div>
  </v-app>
</template>

<script>
import AppBar from "./components/layout/AppBar.vue";
import Sidebar from "./components/layout/Sidebar.vue";
//import { getPeople, getOffboardingPeople } from "./services/firestoreRoutes.js";

import { mapState } from "vuex";

export default {
  name: "App",

  components: {
    AppBar,
    Sidebar,
  },
  async created() {
    // show animation for a bit
    // give msal some time to update url after redirect
  },
  /*watch: {
    authenticated() {
      getPeople();
      getOffboardingPeople();
    },
  },*/
  computed: {
    ...mapState({
      authenticated: (state) => state.user !== undefined,
    }),
  },
};
</script>

<style scoped>
.full-page-loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-shadow: 1px 1px 4px rgba(25, 25, 25, 0.5);
}
</style>

<style>
.keep-lines {
  white-space: pre-wrap;
}

.clickable:hover {
  cursor: pointer;
}

.v-input input {
  max-height: 100% !important;
}
</style>
