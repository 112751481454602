<template>
  <v-row>
    <v-dialog
      v-model="GET_OpenDeleteMailTemplateDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title class="header font-weight-thin text-h5"
          >Do you want to delete &nbsp;
          <b>{{ GET_SelectedMailTemplate.name }} </b>
          &nbsp; ?</v-card-title
        >
        <v-card-text class="card-text">
          <v-list disabled>
            <v-list-item-content
              ><p>Subject: {{ GET_SelectedMailTemplate.subject }}</p>
              <v-spacer class="pb-3"></v-spacer>
              <p>createdBy: {{ GET_SelectedMailTemplate.createdBy }}</p>
            </v-list-item-content>
          </v-list>
        </v-card-text>
        <v-spacer></v-spacer>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" @click="cancel()"> Cancel </v-btn>
          <v-btn depressed color="primary" @click="deleteMailTemplate()">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("settingStore", [
      "GET_OpenDeleteMailTemplateDialog",
      "GET_SelectedMailTemplate",
    ]),
  },
  methods: {
    ...mapMutations("settingStore", [
      "SET_OpenDeleteMailTemplateDialog",
      "SET_SelectedMailTemplate",
    ]),
    ...mapActions("settingStore", ["DELETE_SelectedMailTemplate"]),
    cancel() {
      this.SET_OpenDeleteMailTemplateDialog(false);
      this.SET_SelectedMailTemplate({});
    },
    async deleteMailTemplate() {
      await this.DELETE_SelectedMailTemplate(this.GET_SelectedMailTemplate.id);
      this.SET_OpenDeleteMailTemplateDialog(false);
      this.SET_SelectedMailTemplate({});
    },
  },
};
</script>

<style scoped>
.header {
  justify-content: center;
}
.card-text {
  padding: 16px 24px 10px;
  font-weight: 300;
}

button {
  font-weight: 300;
}
</style>