<template>
  <v-row
    ><v-dialog v-model="GET_OpenDeleteTaskDialog" persistent max-width="600px"
      ><v-card
        ><v-card-title class="header font-weight-thin text-h5"
          >Do you want to delete &nbsp;
          <b>{{ GET_SelectedTask.text }}</b> &nbsp; ?</v-card-title
        ><v-card-text class="card-text"
          ><v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle
                >Time frame:&nbsp;
                <b>{{ GET_SelectedTask.type }}</b></v-list-item-subtitle
              ><v-spacer></v-spacer>
              <v-list-item-subtitle
                >Assigned to:&nbsp;
                <b>{{ GET_SelectedTask.assignedTo }}</b></v-list-item-subtitle
              ><v-spacer></v-spacer>
              <v-list-item-subtitle
                >Created by:&nbsp;
                <b>{{ GET_SelectedTask.createdBy }}</b></v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item></v-card-text
        >
        <v-spacer></v-spacer>
        <ErrorMessageAlert
          v-if="alert"
          v-model="alert"
          :text="GET_ErrorMessage.message"
        />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" @click="cancel()"> Cancel </v-btn>
          <v-btn depressed color="primary" @click="deleteTemplate()">
            Delete
          </v-btn>
        </v-card-actions></v-card
      ></v-dialog
    ></v-row
  >
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ErrorMessageAlert from "../MessageToUser/ErrorMessageAlert.vue";
export default {
  components: { ErrorMessageAlert },
  data() {
    return { alert: false };
  },
  computed: {
    ...mapGetters("settingStore", [
      "GET_OpenDeleteTaskDialog",
      "GET_SelectedTask",
      "GET_SelectedTemplateWithCategory",
      "GET_ErrorMessage",
    ]),
  },
  watch: {
    alert(newValue) {
      if (newValue === false) {
        this.SET_ErrorMessage({});
      }
    },
  },
  methods: {
    ...mapMutations("settingStore", [
      "SET_OpenDeleteTaskDialog",
      "SET_SelectedTask",
      "SET_ErrorMessage",
    ]),
    ...mapActions("settingStore", ["DELETE_SelectedTask"]),

    cancel() {
      this.SET_OpenDeleteTaskDialog(false);
      this.SET_SelectedTask({});
    },

    async deleteTemplate() {
      this.alert = false;
      await this.DELETE_SelectedTask({
        templateType: this.GET_SelectedTemplateWithCategory.category,
        templateId: this.GET_SelectedTask.template,
        id: this.GET_SelectedTask.id,
      });
      if (this.GET_ErrorMessage.alert === true) {
        this.alert = true;
        return;
      }

      this.SET_OpenDeleteTaskDialog(false);
      this.SET_SelectedTask({});
    },
  },
};
</script>

<style scoped>
.header {
  justify-content: center;
}
.card-text {
  padding: 16px 24px 10px;
}

button {
  font-weight: 300;
}
</style>