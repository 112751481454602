import Vue from "vue";
import Vuex from "vuex";
import { login, logout } from "../services/auth.js";
import layoutStore  from "./layoutStore,js";
import settingStore from "./settingStore.js";
import onBoardingStore from "./onBoardingStore.js";

import onboardingPeople from "./onboardingPeople";
import offboardingPeopleStateObejct from "./offboardingPeople";
import onboardingTasks from "./onboardingTasks";
import offboardingTasks from "./offboardingTasks";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    layoutStore,
    settingStore,
    onBoardingStore,
    
    onboardingPeople,
    offboardingPeopleStateObejct,
    onboardingTasks,
    offboardingTasks,
  },
  state: {
    user: undefined,
    signedIn: false,
  },

  actions: {
    async signIn({ commit }) {
      const authResponse = await login();
      if (authResponse) {
        const account = JSON.parse(sessionStorage.getItem("account"));

        commit("setUser", account);
        return account;
      }
    },

    async signOut({ commit }) {
      logout();
      commit("setUser", undefined);
    },
  },

  mutations: {
    setUser(state, data) {
      state.user = data;
      state.signedIn = true;
    },
  },

  getters: {
    user: (state) => state.user,
  },
});
