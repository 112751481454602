import Vue from "vue";
const offboardingPeopleStateObejct = {
  namespaced: true,
  state: {
    offboardingPeoples: null,
  },
  mutations: {
    setPeople(state, newData) {
      state.offboardingPeoples = newData;
    },

    setCompletedTask(state, payLoad) {
      const person = state.offboardingPeoples.find((x) => x.id === payLoad.id);
      const completed = payLoad.updatedTask.completed;
      person.tasks.map((existingTask) => {
        if (existingTask.id === payLoad.updatedTask.id) {
          Vue.set(existingTask, "completed", completed);
        }
        return existingTask;
      });
    },
    removeCompletedTask(state, payLoad) {
      const person = state.offboardingPeoples.find((x) => x.id === payLoad.id);
      person.tasks.map((existingTask) => {
        if (existingTask.id === payLoad.updatedTask.id) {
          Vue.delete(existingTask, "completed");
        }
        return existingTask;
      });
    },
  }, // change state
  getters: {
    getBeforeLastDayOffboard: (state) => (id) => {
      const person = state.offboardingPeoples.find((x) => x.id === id);
      return person.tasks.filter((task) => task.type === "beforeLastDay");
    },
    getLastDayOffboard: (state) => (id) => {
      const person = state.offboardingPeoples.find((x) => x.id === id);
      return person.tasks.filter((task) => task.type === "lastDay");
    },
    getAfterLastDayOffboard: (state) => (id) => {
      const person = state.offboardingPeoples.find((x) => x.id === id);
      return person.tasks.filter((task) => task.type === "afterLastDay");
    },

    getPersonTasksOffboard: (state) => (id) => {
      const person = state.offboardingPeoples.find((x) => x.id === id);
      return person.tasks;
    },
    getTask: (state) => (personId, taskId) => {
      const person = state.offboardingPeoples.find((x) => x.id === personId);
      const task = person.tasks.find((x) => x.id === taskId);
      return task;
    },
  }, // get specific state
  actions: {}, // tell what to do
};

export default offboardingPeopleStateObejct;
