let accessToken = undefined; // graphToken = accessToken
let tokenType = undefined;
let userEmail = undefined;

export const setGraphTokens = (newAccessToken, newTokenType) => {
  accessToken = newAccessToken;
  tokenType = newTokenType;
};

export const setUser = (newUserEmail) => {
  userEmail = newUserEmail;
};

//MS Graph
export const sendEmail = async (options) => {
  try {
    const response = await fetch(
      `${process.env.VUE_APP_GRAPH_URL}/me/sendMail`,
      {
        ...options,
        headers: {
          Authorization: `${tokenType} ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return await response;
  } catch (error) {
    return error;
  }
};

export const graphFetch = async (options) => {
  try {
    const response = await fetch(
      `${process.env.VUE_APP_GRAPH_URL}/users/${userEmail}/sendMail`,
      {
        ...options,
        headers: {
          Authorization: `${tokenType} ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return await response;
  } catch (error) {
    return error;
  }
};

export const msGraphApiFetch = async (endpoint, options) => {
  try {
    const response = await fetch(
      `${process.env.VUE_APP_GRAPH_URL}${endpoint}`,
      {
        ...options,
        headers: {
          Authorization: ` ${tokenType} ${accessToken}`,
          "Content-Type": "application/json",
          ConsistencyLevel: "eventual",
        },
      }
    );
    if (response.status === 401) {
      location.reload();
      return undefined;
    } else if (!response.ok) {
      return undefined;
    }

    if (response.status === 204) {
      return await response;
    }
    return await response.json();
  } catch (e) {
    return undefined;
  }
};

export const getMsGraphApiData = async (endpoint) => {
  return msGraphApiFetch(endpoint, {
    method: "GET",
  });
};

export const postMsGraphApiData = async (endpoint, data) => {
  return msGraphApiFetch(endpoint, {
    method: "POST",
    body: data && JSON.stringify(data),
  });
};

export const putMsGraphApiData = async (endpoint, data) => {
  return msGraphApiFetch(endpoint, {
    method: "PUT",
    body: data && JSON.stringify(data),
  });
};

export const deleteMsGraphApiData = async (endpoint) => {
  return msGraphApiFetch(endpoint, { method: "DELETE" });
};

export const patchMsGraphApiData = async (endpoint, data) => {
  return msGraphApiFetch(endpoint, {
    method: "PATCH",
    body: data && JSON.stringify(data),
  });
};

const graphFetchWithOutJsonResponse = async (endpoint, options) => {
  try {
    return await fetch(`${process.env.VUE_APP_GRAPH_URL}${endpoint}`, {
      ...options,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } catch {
    return undefined;
  }
};

export const graphGetBlob = async (url) => {
  const response = await graphFetchWithOutJsonResponse(url, {
    method: "GET",
  });
  return response.ok ? response.blob() : undefined;
};
