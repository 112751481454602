<template>
  <v-container
    v-if="
      GET_SelectedPersonWithTasks &&
      Object.keys(this.GET_SelectedPersonWithTasks).length != 0
    "
  >
    <v-row class="header">
      <v-col cols="3" sm="4">
        <figure class="figure">
          <span
            class="selected-person-initials"
            :style="`background-color: ${getRandomColour}`"
            >{{ selectedPersonInitials }}</span
          >
        </figure>
      </v-col>
      <v-col cols="4" sm="4"
        ><h4>
          Name:
          {{ GET_SelectedPersonWithTasks.firstName }}
          {{ GET_SelectedPersonWithTasks.lastName }}
        </h4></v-col
      >
      <v-col cols="4" sm="4">
        <h4>Start date: {{ GET_SelectedPersonWithTasks.startDate }}</h4>
      </v-col>
    </v-row>
    <v-row class="mt-10">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header class="expansion-header"
            >Person Info</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <PersonInfo />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-row class="edit-row">
      <v-switch
        class="edit-switch"
        :value="GET_EditTask"
        :label="GET_EditTask === false ? 'Edit Off' : 'Edit On'"
        @change="updateSwitch"
      >
      </v-switch>
    </v-row>
    <v-row class="btn-row">
      <v-btn
        v-if="GET_EditTask"
        depressed
        color="primary"
        class="btn"
        @click="addTask()"
      >
        Add task
      </v-btn>
      <div v-else class="else"></div>
    </v-row>
    <v-row>
      <SelectedPersonTableTasks />
    </v-row>
    <EditPersonInfoDialog v-if="GET_OpenEditSelectedPersonInfo" />
    <CreateATaskOnProgressDialog v-if="GET_OpenCreateATaskOnProgressDialog" />
  </v-container>
  <v-container v-else-if="alert">
    <v-row class="header">
      <v-col cols="3" sm="4">
        <figure class="figure">
          <span
            class="selected-person-initials"
            :style="`background-color: ${getRandomColour}`"
            >N/A</span
          >
        </figure>
      </v-col>
      <v-col cols="4" sm="4"><h4>Name: N/A</h4></v-col>
      <v-col cols="4" sm="4">
        <h4>Start date: N/A</h4>
      </v-col> </v-row
    ><v-row
      ><ErrorMessageAlert
        v-model="alert"
        :text="GET_OnOffBoardingErrorMessage.message" /></v-row
  ></v-container>
  <v-container v-else>
    <div class="full-page-loader primary-background">
      <v-progress-circular
        :size="100"
        :width="7"
        color="purple"
        indeterminate
      />
      <span class="text-h4 purple--text">Loading </span>
    </div>
  </v-container>
</template>


<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import PersonInfo from "./PersonInfo.vue";
import SelectedPersonTableTasks from "./SelectedPersonTableTasks.vue";
import ErrorMessageAlert from "../MessageToUser/ErrorMessageAlert.vue";
import EditPersonInfoDialog from "../OnProgressDialog/EditPersonInfoDialog.vue";
import CreateATaskOnProgressDialog from "../OnProgressDialog/CreateATaskOnProgressDialog.vue";

export default {
  components: {
    PersonInfo,
    SelectedPersonTableTasks,
    ErrorMessageAlert,
    EditPersonInfoDialog,
    CreateATaskOnProgressDialog,
  },
  data() {
    return {
      alert: false,
      loading: true,
      image: {
        color: "",
        picture: "",
        isCompleted: false,
      },
    };
  },
  computed: {
    ...mapGetters("onBoardingStore", [
      "GET_SelectedPersonId",
      "GET_SelectedPersonWithTasks",
      "GET_NewOrUpdatedPerson",
      "GET_OpenEditSelectedPersonInfo",
      "GET_EditTask",
      "GET_OpenCreateATaskOnProgressDialog",
      "GET_OnOffBoardingErrorMessage",
    ]),

    getRandomColour() {
      const colors =
        "#" + (((1 << 24) * Math.random()) | 0).toString(16).padStart(6, "0");
      return colors;
    },

    selectedPersonInitials() {
      if (!this.GET_SelectedPersonWithTasks) return;
      return (
        this.GET_SelectedPersonWithTasks.firstName.charAt(0) +
        this.GET_SelectedPersonWithTasks.lastName.charAt(0)
      );
    },
  },
  async created() {
    this.SET_EditTask(false);
    this.SET_OpenSelectedMailTemplateFromPersonTask(false);
    this.SET_SelectedPersonId(this.$route.params.id);
    await this.FETCH_OnBoardingAndOfBoardingPersonWithTasks({
      id: this.GET_SelectedPersonId,
      type: "onBoarding",
    });
    if (this.GET_OnOffBoardingErrorMessage.alert === true) {
      this.alert = true;
    }
  },

  watch: {
    async GET_NewOrUpdatedPerson(newValue) {
      if (newValue === true) {
        this.SET_SelectedPersonId(this.$route.params.id);
        await this.FETCH_OnBoardingAndOfBoardingPersonWithTasks({
          id: this.GET_SelectedPersonId,
          type: "onBoarding",
        });
        if (this.GET_OnOffBoardingErrorMessage.alert === true) {
          this.alert = true;
        }
        this.SET_NewOrUpdatedPerson(false);
      }
    },
    alert(newValue) {
      if (newValue === false) {
        this.SET_OnOffBoardingErrorMessage({});
      }
    },
  },
  methods: {
    ...mapMutations("onBoardingStore", [
      "SET_SelectedPersonId",
      "SET_EditTask",
      "SET_NewOrUpdatedPerson",
      "SET_OpenCreateATaskOnProgressDialog",
      "SET_OpenSelectedMailTemplateFromPersonTask",
    ]),
    ...mapActions("onBoardingStore", [
      "FETCH_OnBoardingAndOfBoardingPersonWithTasks",
    ]),

    updateSwitch(value) {
      this.SET_EditTask(!!value);
    },

    addTask() {
      this.SET_OpenCreateATaskOnProgressDialog(true);
    },
  },
};
</script>

<style scoped lang="scss">
.full-page-loader {
  text-shadow: 1px 1px 4px rgba(25, 25, 25, 0.5);
  position: absolute;
  left: 50%;
  top: 50%;
  -moz-transform: translate(-50%, -50%); /* Firefox */
  -ms-transform: translate(-50%, -50%); /* IE 9 */
  -webkit-transform: translate(-50%, -50%); /* Safari and Chrome*/
  -o-transform: translate(-50%, -50%); /* Opera */
  transform: translate(-50%, -50%);

  /* optional size in px or %: */
  width: 100px;
  height: 100px;
}
.header {
  padding-top: 50px;
}
.figure {
  height: 56px;
  width: 56px;
  padding: 0;
  position: relative;
  cursor: pointer;
  :hover {
    -webkit-filter: brightness(110%);
    filter: brightness(110%);
  }
  border: none;
}

.selected-person-initials {
  display: flex;
  flex: 1;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-transform: uppercase;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
}
.expansion-header {
  font-weight: bolder;
  font-size: 20px;
}
.edit-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px 0px 0px 0px;
}
.edit-switch {
  font-size: 16px;
  font-weight: bolder;
}
.btn-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px 0px 0px 0px;
}
.btn {
  font-weight: 300;
}
.else {
  height: 36px;
}
</style>