<template>
  <v-row
    ><v-dialog
      v-model="GET_OpenDeleteTemplateDialog"
      persistent
      max-width="600px"
      ><v-card
        ><v-card-title class="header font-weight-thin text-h5"
          >Do you want to delete &nbsp;
          <b>{{ GET_SelectedTemplateWithCategory.name }}</b> &nbsp;
          ?</v-card-title
        >
        <v-spacer class="pb-8"></v-spacer>
        <ErrorMessageAlert
          v-if="alert"
          v-model="alert"
          :text="GET_ErrorMessage.message"
        />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="action-btn" depressed color="primary" @click="cancel()">
            Cancel
          </v-btn>
          <v-btn
            class="action-btn"
            depressed
            color="primary"
            @click="deleteTemplate()"
          >
            Delete
          </v-btn>
        </v-card-actions></v-card
      ></v-dialog
    ></v-row
  >
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ErrorMessageAlert from "../MessageToUser/ErrorMessageAlert.vue";
export default {
  components: { ErrorMessageAlert },
  data() {
    return {
      alert: false,
    };
  },
  computed: {
    ...mapGetters("settingStore", [
      "GET_OpenDeleteTemplateDialog",
      "GET_SelectedTemplateWithCategory",
      "GET_ErrorMessage",
    ]),
  },
  watch: {
    alert(newValue) {
      if (newValue === false) {
        this.SET_ErrorMessage({});
      }
    },
  },
  methods: {
    ...mapMutations("settingStore", ["SET_OpenDeleteTemplateDialog"]),
    ...mapActions("settingStore", ["DELETE_SelectedTemplate"]),

    cancel() {
      this.SET_OpenDeleteTemplateDialog(false);
    },

    async deleteTemplate() {
      this.alert = false;
      await this.DELETE_SelectedTemplate({
        id: this.GET_SelectedTemplateWithCategory.id,
        templateType: this.GET_SelectedTemplateWithCategory.category,
      });
      if (this.GET_ErrorMessage.alert === true) {
        this.alert = true;
        return;
      }
      this.SET_OpenDeleteTemplateDialog(false);
    },
  },
};
</script>


<style scoped lang="scss">
.header {
  justify-content: center;
}
.card-text {
  padding: 16px 24px 10px;
}

.action-btn {
  font-weight: 300;
}
</style>