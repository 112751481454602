<template>
  <v-row>
    <v-dialog
      v-model="GET_OpenSelectedOnBoardingTask"
      persistent
      max-width="800px"
      ><v-card
        ><v-card-title class="header font-weight-thin text-h4"
          >Do you want to edit &nbsp;
          <b>{{ GET_SelectedOnBoardingTask.text }}</b> &nbsp;? </v-card-title
        ><v-card-subtitle class="mt-2"
          >Editing a task here will not affect the original tasks on settings.
          The changes will only effect this task for
          <b
            >{{ GET_SelectedPersonWithTasks.firstName }}
            {{ GET_SelectedPersonWithTasks.lastName }}</b
          >
          .</v-card-subtitle
        ><v-form
          ref="form"
          v-model="valid"
          :lazy-validation="lazy"
          class="form"
        >
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="selected.text"
                :rules="textRules"
                label="Title*"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="selected.type"
                @focus="$event.target.click()"
                :items="GET_SelectedPersonWithTasks.templateTypes"
                :rules="[(v) => !!v || 'Type is required']"
                :menu-props="{ top: false, offsetY: true }"
                label="Time frame*"
              ></v-select
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                v-model="assignedToWithId"
                @focus="$event.target.click()"
                :items="employeesInfo"
                :menu-props="{ top: false, offsetY: true }"
                item-text="displayName"
                label="Assign to"
                return-object
              ></v-select
            ></v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="emailTemplateWithInfo"
                @focus="$event.target.click()"
                :items="listOfEmailTemplate"
                :menu-props="{ top: false, offsetY: true }"
                item-text="name"
                label="Email action"
                return-object
              ></v-select
            ></v-col>
          </v-row>
          <v-row
            ><v-col>
              <v-textarea
                v-model="selected.description"
                auto-grow
                outlined
                label="Description"
                rows="3"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>

          <small>*indicates required field</small>

          <ErrorMessageAlert
            v-if="alertDelete"
            v-model="alertDelete"
            :text="GET_OnOffBoardingErrorMessage.message"
          />
          <ErrorMessageAlert
            v-if="alert"
            v-model="alert"
            :text="GET_OnOffBoardingErrorMessage.message"
          />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="primary"
              class="action-btn"
              @click="deleteTask()"
            >
              DELETE
            </v-btn>
            <v-btn
              depressed
              color="primary"
              class="action-btn"
              @click="reset()"
            >
              Cancel
            </v-btn>
            <v-btn
              depressed
              color="primary"
              class="action-btn"
              @click="validate()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form></v-card
      ></v-dialog
    >
  </v-row>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getAllCompanyUsers } from "../../services/msGraphActions";
import ErrorMessageAlert from "../MessageToUser/ErrorMessageAlert.vue";
export default {
  components: { ErrorMessageAlert },
  data() {
    return {
      alert: false,
      alertDelete: false,
      valid: true,
      lazy: true,
      textRules: [(v) => !!v || "Title is required"],
      employees: null,
      assignedToWithId: {},
      emailTemplateWithInfo: {},
      title: "",
      selected: {
        text: "",
        type: "",
        assignedTo: "",
        assignedToID: "",
        emailTemplate: "",
        emailTemplateId: "",
        emailTemplateName: "",
        description: "",
      },
    };
  },
  computed: {
    ...mapGetters("settingStore", ["GET_MailTemplates"]),
    ...mapGetters("onBoardingStore", [
      "GET_OpenSelectedOnBoardingTask",
      "GET_SelectedOnBoardingTask",
      "GET_SelectedPersonWithTasks",
      "GET_OnOffBoardingErrorMessage",
    ]),

    employeesInfo() {
      try {
        const information = this.employees?.value.map((value) => {
          return (value = { displayName: value.displayName, id: value.id });
        });
        information.unshift({ displayName: "", id: "" });
        return information;
      } catch {
        return [];
      }
    },
    listOfEmailTemplate() {
      try {
        const list = [...this.GET_MailTemplates];
        list.unshift({ id: "", name: "" });
        return list;
      } catch {
        return [];
      }
    },
  },
  async created() {
    this.employees = await getAllCompanyUsers();
    await this.FETCH_MailTemplates();
    this.selectedTask();
  },
  watch: {
    alert(newValue) {
      if (newValue === false) {
        this.SET_OnOffBoardingErrorMessage({});
      }
    },
  },

  methods: {
    ...mapMutations("onBoardingStore", [
      "SET_OpenSelectedOnBoardingTask",
      "SET_SelectedOnBoardingTask",
      "SET_OnOffBoardingErrorMessage",
    ]),
    ...mapActions("settingStore", ["FETCH_MailTemplates"]),
    ...mapActions("onBoardingStore", [
      "EDIT_OnBoardingAndOffBoardingPersonTask",
      "DELETE_PersonTaskOnprogress",
    ]),

    selectedTask() {
      const task = JSON.parse(JSON.stringify(this.GET_SelectedOnBoardingTask));
      this.selected = task;
      this.assignedToWithId = {
        displayName: this.selected.assignedTo,
        id: this.selected.assignedToID,
      };
      this.emailTemplateWithInfo = {
        id: this.selected.emailTemplateId,
        name: this.selected.emailTemplateName,
      };
    },
    reset() {
      this.$refs.form.reset();
      this.SET_SelectedOnBoardingTask({});
      this.SET_OpenSelectedOnBoardingTask(false);
    },
    async validate() {
      this.alert = false;
      this.$refs.form.validate();
      (this.selected.assignedTo = this.assignedToWithId.displayName),
        (this.selected.assignedToID = this.assignedToWithId.id),
        (this.selected.emailTemplateId = this.emailTemplateWithInfo.id);
      this.selected.emailTemplateName = this.emailTemplateWithInfo.name;

      const editInfo = {
        type: "onBoarding",
        personId: this.GET_SelectedPersonWithTasks.id,
        taskId: this.GET_SelectedOnBoardingTask.id,
        selected: this.selected,
      };

      await this.EDIT_OnBoardingAndOffBoardingPersonTask(editInfo);
      if (this.GET_OnOffBoardingErrorMessage.alert === true) {
        this.alert = true;
        return;
      }
      this.SET_SelectedOnBoardingTask({});
      this.SET_OpenSelectedOnBoardingTask(false);
    },

    async deleteTask() {
      this.alertDelete = false;
      const item = {
        type: "onBoarding",
        personId: this.GET_SelectedPersonWithTasks.id,
        taskId: this.GET_SelectedOnBoardingTask.id,
      };
      await this.DELETE_PersonTaskOnprogress(item);
      if (this.GET_OnOffBoardingErrorMessage.alert === true) {
        this.alertDelete = true;
        return;
      }
      this.SET_SelectedOnBoardingTask({});
      this.SET_OpenSelectedOnBoardingTask(false);
    },
  },
};
</script>

<style scoped lang='scss'>
.form {
  padding: 16px 24px 10px;
}
.action-btn {
  font-weight: 300;
}
</style>

