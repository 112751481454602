<template>
  <v-row justify="center">
    <v-dialog
      v-model="GET_OpenCreateATaskOffProgressDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title class="header font-weight-thin text-h4"
          >Create a new task</v-card-title
        >

        <v-form ref="form" v-model="valid" :lazy-validation="lazy" class="form">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="selected.text"
                :rules="textRules"
                label="Title*"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="selected.type"
                @focus="$event.target.click()"
                :items="GET_SelectedPersonWithTasks.templateTypes"
                :rules="[(v) => !!v || 'Type is required']"
                :menu-props="{ top: false, offsetY: true }"
                label="Time frame*"
              ></v-select
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                v-model="assignedToWithId"
                @focus="$event.target.click()"
                :items="employeesInfo"
                :menu-props="{ top: false, offsetY: true }"
                item-text="displayName"
                label="Assign to"
                return-object
              ></v-select
            ></v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="emailTemplateWithInfo"
                @focus="$event.target.click()"
                :items="listOfEmailTemplate"
                :menu-props="{ top: false, offsetY: true }"
                item-text="name"
                label="Email action"
                return-object
              ></v-select
            ></v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="selected.description"
                auto-grow
                outlined
                label="Description"
                rows="3"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-spacer></v-spacer>

          <small>*indicates required field</small>
          <ErrorMessageAlert
            v-if="alert"
            v-model="alert"
            :text="GET_OnOffBoardingErrorMessage.message"
          />

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="primary" @click="reset()"> Close </v-btn>
            <v-btn
              depressed
              color="primary"
              :disabled="selected.type == '' || selected.text == ''"
              @click="validate()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getAllCompanyUsers } from "../../services/msGraphActions.js";
import ErrorMessageAlert from "../MessageToUser/ErrorMessageAlert.vue";
export default {
  components: { ErrorMessageAlert },
  data() {
    return {
      alert: false,
      valid: true,
      lazy: true,
      textRules: [(v) => !!v || "Title is required"],
      employees: null,
      assignedToWithId: {},
      emailTemplateWithInfo: {},

      selected: {
        text: "",
        type: "",
        assignedTo: "",
        assignedToId: "",
        emailTemplateId: "",
        emailTemplateName: "",
        description: "",
      },
    };
  },
  computed: {
    ...mapGetters("settingStore", ["GET_MailTemplates"]),
    ...mapGetters("onBoardingStore", [
      "GET_OpenCreateATaskOffProgressDialog",
      "GET_SelectedPersonWithTasks",
      "GET_OnOffBoardingErrorMessage",
    ]),

    employeesInfo() {
      try {
        const information = this.employees?.value.map((value) => {
          return (value = { displayName: value.displayName, id: value.id });
        });

        information.unshift({ displayName: "", id: "" });
        return information;
      } catch {
        return [];
      }
    },
    listOfEmailTemplate() {
      try {
        const list = [...this.GET_MailTemplates];
        list.unshift({ id: "", name: "" });
        return list;
      } catch {
        return [];
      }
    },
  },
  async created() {
    this.employees = await getAllCompanyUsers();
    await this.FETCH_MailTemplates();
  },
  watch: {
    alert(newValue) {
      if (newValue === false) {
        this.SET_OnOffBoardingErrorMessage({});
      }
    },
  },

  methods: {
    ...mapMutations("onBoardingStore", [
      "SET_OpenCreateATaskOffProgressDialog",
      "SET_OnOffBoardingErrorMessage",
    ]),
    ...mapActions("settingStore", ["FETCH_MailTemplates"]),
    ...mapActions("onBoardingStore", ["POST_NewTaskOnprogress"]),

    reset() {
      this.$refs.form.reset();
      this.SET_OpenCreateATaskOffProgressDialog(false);
    },

    async validate() {
      this.alert = false;
      this.$refs.form.validate();
      const template = {
        type: "offBoarding",
        personId: this.GET_SelectedPersonWithTasks.id,
      };
      (this.selected.assignedTo = this.assignedToWithId.displayName),
        (this.selected.assignedToId = this.assignedToWithId.id),
        (this.selected.emailTemplateId = this.emailTemplateWithInfo.id);
      this.selected.emailTemplateName = this.emailTemplateWithInfo.name;
      await this.POST_NewTaskOnprogress([this.selected, template]);
      if (this.GET_OnOffBoardingErrorMessage.alert === true) {
        this.alert = true;
        return;
      }

      this.SET_OpenCreateATaskOffProgressDialog(false);
    },
  },
};
</script>

<style scoped>
.form {
  padding: 16px 24px 10px;
}
</style>