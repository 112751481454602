import {postApiData, getApiData, patchApiData, deleteApiData } from '../api/api';

const onBoardingStore = {
  namespaced: true,
  state: {
    editTask: false,
    newOrUpdatedPerson: false,
    openEditSelectedPersonInfo: false,
    openSelectedMailTemplateFromPersonTask: false,
    openSelectedOnBoardingTask:false,
    openCreateATaskOnProgressDialog: false,
    openShowSelectedOnBoardingTask: false,
    openCreateATaskOffProgressDialog: false,
    openEditSelectedOffBoardingPersonInfo: false,
    openSelectedOffBoardingTask: false,
    openShowSelectedOffBoardingTask: false,
    openSelectedMailTemplateFromOffBoardingPersonTask: false,
    newOrUpdatedMailTemplate: false,

    selectedPersonId: "",
    selectedPersonWithTasks: {},
    selectedMailTemplateFromPersonTask: {},
    selectedOnBoardingTask:{},
    selectedTaskWithMailInfo:{},
    onOffBoardingErrorMessage: {},
    selectedOffBoardingTask: {},
    employees: {}
    

  },
  getters: {
    GET_EditTask: (state) => state.editTask,
    GET_NewOrUpdatedPerson: (state) => state.newOrUpdatedPerson,
    GET_OpenSelectedMailTemplateFromPersonTask: (state) => state.openSelectedMailTemplateFromPersonTask,
    GET_OpenEditSelectedPersonInfo: (state) => state.openEditSelectedPersonInfo,
    GET_OpenSelectedOnBoardingTask: (state) => state.openSelectedOnBoardingTask,
    GET_OpenCreateATaskOnProgressDialog: (state) => state.openCreateATaskOnProgressDialog,
    GET_OpenShowSelectedOnBoardingTask: (state) => state.openShowSelectedOnBoardingTask,
    GET_OpenCreateATaskOffProgressDialog: (state) => state.openCreateATaskOffProgressDialog,
    GET_OpenShowSelectedOffBoardingTask: (state) => state.openShowSelectedOffBoardingTask,
    GET_OpenSelectedMailTemplateFromOffBoardingPersonTask: (state) => state.openSelectedMailTemplateFromOffBoardingPersonTask,
    GET_OpenSelectedOffBoardingTask: (state) => state.openSelectedOffBoardingTask,
    GET_OpenEditSelectedOffBoardingPersonInfo: (state) => state.openEditSelectedOffBoardingPersonInfo,
    GET_NewOrUpdatedMailTemplate: (state) => state.newOrUpdatedMailTemplate,

    GET_SelectedPersonId: (state) => state.selectedPersonId,
    GET_SelectedPersonWithTasks: (state) => state.selectedPersonWithTasks,
    GET_SelectedMailTemplateFromPersonTask:(state) => state.selectedMailTemplateFromPersonTask,
    GET_SelectedOnBoardingTask: (state) => state.selectedOnBoardingTask,
    GET_SelectedTaskWithMail: (state) => state.selectedTaskWithMail,
    GET_OnOffBoardingErrorMessage: (state) => state.onOffBoardingErrorMessage,
    GET_SelectedOffBoardingTask: (state) => state.selectedOffBoardingTask,
    GET_Employees: (state) => state.employees,
  },
  mutations: {
    SET_EditTask(state, boolean){
      state.editTask = boolean
    },
    SET_NewOrUpdatedPerson(state, boolean){
      state.newOrUpdatedPerson = boolean
    },
    SET_OpenSelectedMailTemplateFromPersonTask(state, boolean){
      state.openSelectedMailTemplateFromPersonTask = boolean
    },
    SET_OpenEditSelectedPersonInfo(state, boolean){
      state.openEditSelectedPersonInfo = boolean
    },
    SET_OpenSelectedOnBoardingTask(state, boolean){
      state.openSelectedOnBoardingTask = boolean
    },
    SET_OpenCreateATaskOnProgressDialog(state, boolean){
      state.openCreateATaskOnProgressDialog = boolean
    },
    SET_OpenShowSelectedOnBoardingTask(state, boolean){
      state.openShowSelectedOnBoardingTask = boolean
    },
    SET_OpenCreateATaskOffProgressDialog(state, boolean){
      state.openCreateATaskOffProgressDialog = boolean
    },
    SET_OpenEditSelectedOffBoardingPersonInfo(state, boolean){
      state.openEditSelectedOffBoardingPersonInfo = boolean
    },
    SET_OpenSelectedOffBoardingTask(state, boolean){
      state.openSelectedOffBoardingTask = boolean
    },
    SET_OpenShowSelectedOffBoardingTask(state, boolean){
      state.openShowSelectedOffBoardingTask = boolean
    },
    SET_OpenSelectedMailTemplateFromOffBoardingPersonTask(state, boolean){
      state.openSelectedMailTemplateFromOffBoardingPersonTask = boolean
    },
    SET_NewOrUpdatedMailTemplate(state, boolean){
      state.newOrUpdatedMailTemplate = boolean
    },

    SET_SelectedPersonId(state, updateSelectedPersonId){
      state.selectedPersonId = updateSelectedPersonId 
    },
    SET_SelectedPersonWithTasks(state, updatedSelectedPersonWithTasks){
      state.selectedPersonWithTasks = updatedSelectedPersonWithTasks
    },
    SET_SelectedMailTemplateFromPersonTask(state, updateSelectedMailTemplateFromPersonTask){
      state.selectedMailTemplateFromPersonTask = updateSelectedMailTemplateFromPersonTask
    },
    SET_SelectedOnBoardingTask(state, updatedSelectedOnBoardingTask){
      state.selectedOnBoardingTask = updatedSelectedOnBoardingTask
    },
    SET_SelectedTaskWithMail(state, updateSelectedTaskWithMail){
      state.selectedTaskWithMail = updateSelectedTaskWithMail
    },
    SET_OnOffBoardingErrorMessage(state, updateOnOffBoardingErrorMessage){
      state.onOffBoardingErrorMessage = updateOnOffBoardingErrorMessage
    },
    SET_SelectedOffBoardingTask(state, updateSelectedOffBoardingTask){
      state.selectedOffBoardingTask = updateSelectedOffBoardingTask
    },
    SET_Employees(state, updateEmployees){
      state.employees = updateEmployees
    }

  },
  actions: {

    //---------------------Onboarding------------------------------//
    async POST_CreatedOnAndOffBoardingPerson(context, item){
      const json = false;
      try{
        const response = await postApiData(`people/${item.type}`, item.person , json
        );
        if (response.status === 201) {
          const result = await response.json()
          context.commit('SET_SelectedPersonId', result.id)
          context.commit('SET_NewOrUpdatedPerson', true)
       } else {
        await context.commit('SET_OnOffBoardingErrorMessage', {alert: true, message: 'Failed to save person, please try again'})
       }
        return response
      } catch(err){
        console.log('error')
        return err
      }
    },

    async DELETE_PersonWithTask(context, item){
      const json = false;

      const response = await deleteApiData(`people/${item.type}/${item.id}`,  json);
      try{
        if(response.status === 200){
          context.commit('SET_NewOrUpdatedPerson', true)
        } else {
          await context.commit('SET_OnOffBoardingErrorMessage', {alert: true,  message : 'Failed to delete the task, please try again.' })
        }
        return response
    } catch(err){
      console.log('error')
      return err
    }
  },
   
    
    //---------------------OnProgress------------------------------//
    async FETCH_OnBoardingAndOfBoardingPersonWithTasks(context, item){
      const json = false;
      try {
        const response = await getApiData(`people/${item.type}/${item.id}`, json);
          if(response.status === 200){
            const result = await response.json();
            
            await context.commit('SET_SelectedPersonWithTasks', result)
           
          } else {
            await context.commit('SET_OnOffBoardingErrorMessage', {alert: true, message : 'Failed to fetch templates, please refesh the page. If it doesnt work contact the administrator. ' })
          }
          return response
      } catch(err){
        console.log('error')
        return
      }
    },

    async EDIT_OnBoardingAndOffBoardingPersonInfo(context, item){
      const json = false;
  
      try {
        const response = await patchApiData(`people/${item.type}/${item.item.id}`,item.item, json)
        if(response.status === 200){
        context.commit('SET_NewOrUpdatedPerson', true)
        } else {
          await context.commit ('SET_OnOffBoardingErrorMessage', {alert: true, message : 'Failed to update person info, please try again. If it doesnt work contact the administrator. ' })
        }
        return response
      } catch(err){
        console.log('error')
        return err
      }
    },

    async EDIT_TaskStatus(context, item){
      console.log(item)
      const json = false;
      try {
        const response = await patchApiData(`people/${item.infoToSend.type}/${item.infoToSend.personId}/task/${item.infoToSend.taskId}/status`,  item.updatedTask, json);
        if(response.status === 200){
          context.commit('SET_NewOrUpdatedPerson', true)
        } else {
          await context.commit('SET_OnOffBoardingErrorMessage', {alert: true,  message : 'Failed to update task status, please try again. If it doesnt work contact the administrator. ' })
        }
        return response
      } catch(err){
        console.log('error')
        return err
      }
    },

    async EDIT_OnBoardingAndOffBoardingPersonTask(context, item){
      console.log(item)
      const json = false;
      try {
        const response = await patchApiData(`people/${item.type}/${item.personId}/task/${item.taskId}`,  item.selected, json);
        if(response.status === 200){
          context.commit('SET_NewOrUpdatedPerson', true)
        } else {
          await context.commit('SET_OnOffBoardingErrorMessage', {alert: true,  message : 'Failed to edit the task, please try again. If it doesnt work contact the administrator. ' })
        }
        return response
      } catch(err){
        console.log('error')
        return err
      }
    },

    async DELETE_PersonTaskOnprogress(context, item){
      const json = false;
      try {
        const response = await deleteApiData(`people/${item.type}/${item.personId}/task/${item.taskId}`,  json);
        if(response.status === 200){
          context.commit('SET_NewOrUpdatedPerson', true)
        } else {
          await context.commit('SET_OnOffBoardingErrorMessage', {alert: true,  message : 'Failed to delete the task, please try again.' })
        }
        return response
      } catch(err){
        console.log('error')
        return err
      }
    },

    async POST_NewTaskOnprogress(context, item){

      const json = false;
      try{
        const response = await postApiData(`people/${item[1].type}/${item[1].personId}/task`, item[0] , json
        );
        if (response.status === 201) {
          await context.commit('SET_NewOrUpdatedPerson', true)
       }else {
        await context.commit('SET_OnOffBoardingErrorMessage', {alert: true,  message : 'Failed to create the task, please try again.' })
       }
        return response
      } catch(err){
        console.log('error')
        return err
      }
    },
    //--------------------MailTemplate-------------------------------//
    async FETCH_SelectedMailTemplateFromPersonTask(context, item){
     
      const json = false;
      try{
        const response = await getApiData(`people/${item.type}/${item.personId}/email/${item.emailId}`, json);
        if(response.status === 200){
          const result = await response.json()
          await context.commit('SET_SelectedMailTemplateFromPersonTask', result);
        } else {
          await context.commit('SET_OnOffBoardingErrorMessage', {alert: true,  message : 'Failed to fetch the selected mail template, please try again.' })
        }
        return response;
      } catch(err){
        console.log('error')
        return
      }
    },
    async EDIT_SelectedMailTemplateFromPersonTask(context, template){
      const json = false;
     
      try {
        const response = await patchApiData(`people/${template.type}/${template.personId}/email/${template.selected.id}`,  template.selected, json)
        if(response.status === 200){
          
        context.commit('SET_NewOrUpdatedMailTemplate', true)
        } else {
          await context.commit('SET_OnOffBoardingErrorMessage', {alert: true,  message : 'Failed to edit the selected mail template, please try again.' })
        }
        return response
      } catch(err){
        console.log('error')
        return err
      }
    },
    

  }
}

export default onBoardingStore;