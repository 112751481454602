var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pl-0 pr-0 pt-10"},[(_vm.alert)?_c('ErrorMessageAlert',{attrs:{"text":_vm.GET_OnOffBoardingErrorMessage.message},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}}):_vm._e(),(_vm.emailAlert)?_c('ErrorMessageAlert',{attrs:{"text":_vm.GET_OnOffBoardingErrorMessage.message},model:{value:(_vm.emailAlert),callback:function ($$v) {_vm.emailAlert=$$v},expression:"emailAlert"}}):_vm._e(),(_vm.GET_EditTask)?_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.sortItems,"hide-default-footer":true,"item-key":"name","group-by":"position","disable-pagination":"","single-select":""},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var isOpen = ref.isOpen;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-btn',{ref:group,staticClass:"icon-btn",attrs:{"x-small":"","icon":"","data-open":isOpen,"disable-items-per-page":""}},[(isOpen)?_c('v-icon',[_vm._v("mdi-minus")]):_vm._e()],1),_c('span',{staticClass:"mx-5 font-weight-bold"},[_vm._v(_vm._s(_vm.sortList[group]))])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.emailTemplateId && item.emailTemplateId != '')?_c('v-icon',[_vm._v("mdi-email-arrow-right-outline")]):_vm._e()]}},{key:"item.completed",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"value":item.completed,"ripple":false,"disabled":Boolean(item.completed),"readonly":""}})]}}],null,true)}):_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.sortItems,"hide-default-footer":true,"item-key":"name","group-by":"position","disable-pagination":""},on:{"click:row":_vm.showSelectedItem},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-btn',{ref:group,staticClass:"icon-btn",attrs:{"x-small":"","icon":"","data-open":isOpen,"disable-items-per-page":""},on:{"click":toggle}},[(isOpen)?_c('v-icon',[_vm._v("mdi-minus")]):_c('v-icon',[_vm._v("mdi-plus")])],1),_c('span',{staticClass:"mx-5 font-weight-bold"},[_vm._v(_vm._s(_vm.sortList[group]))])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.emailTemplateId && item.emailTemplateId != '')?_c('v-icon',{on:{"click":function($event){return _vm.openEmailTemplate(item)}}},[_vm._v("mdi-email-arrow-right-outline")]):_vm._e()]}},{key:"item.completed",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"ripple":false},on:{"click":function($event){return _vm.updateTaskStatus(item)}},model:{value:(item.completed),callback:function ($$v) {_vm.$set(item, "completed", $$v)},expression:"item.completed"}})]}}],null,true)}),(_vm.GET_OpenSelectedOnBoardingTask)?_c('EditOnBoardingPersonTaskDialog'):_vm._e(),(_vm.GET_OpenShowSelectedOnBoardingTask)?_c('ShowSelectedTaskDialog'):_vm._e(),(_vm.GET_OpenSelectedMailTemplateFromPersonTask)?_c('PersonCopyMailTemplate'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }