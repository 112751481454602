<template>
  <v-row>
    <v-dialog v-model="GET_OpenEditTaskDialog" persistent max-width="600px"
      ><v-card
        ><v-card-title class="header font-weight-thin text-h4"
          >Do you want to edit &nbsp; <b>{{ GET_SelectedTask.text }}</b> ?
        </v-card-title>
        <v-card-subtitle class="mt-2"
          >Editing a task here will not affect the tasks on the ones that are in
          the progress of being Onboarded. The changes will only effect new
          employees.</v-card-subtitle
        ><v-form
          ref="form"
          v-model="valid"
          :lazy-validation="lazy"
          class="form"
        >
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="selected.text"
                :rules="textRules"
                label="Title*"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="selected.type"
                @focus="$event.target.click()"
                :items="GET_SelectedTemplateWithCategory.types"
                :rules="[(v) => !!v || 'Type is required']"
                :menu-props="{ top: false, offsetY: true }"
                label="Time frame*"
              ></v-select
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                v-model="assignedToWithId"
                @focus="$event.target.click()"
                :items="employeesInfo"
                :menu-props="{ top: false, offsetY: true }"
                item-text="displayName"
                label="Assign to"
                return-object
              ></v-select
            ></v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="emailTemplateWithInfo"
                @focus="$event.target.click()"
                :items="listOfEmailTemplate"
                :menu-props="{ top: false, offsetY: true }"
                item-text="name"
                label="Email action"
                return-object
              ></v-select
            ></v-col>
          </v-row>
          <v-row
            ><v-col>
              <v-textarea
                v-model="selected.description"
                auto-grow
                outlined
                label="Description"
                rows="3"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>

          <small>*indicates required field</small>
          <ErrorMessageAlert
            v-if="alert"
            v-model="alert"
            :text="GET_ErrorMessage.message"
          />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="primary" @click="reset()"> Close </v-btn>
            <v-btn
              depressed
              color="primary"
              @click="validate()"
              :disabled="selected.type == '' || selected.text == ''"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form></v-card
      ></v-dialog
    >
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { getAllCompanyUsers } from "../../services/msGraphActions.js";
import ErrorMessageAlert from "../MessageToUser/ErrorMessageAlert.vue";

export default {
  components: { ErrorMessageAlert },
  data() {
    return {
      alert: false,
      valid: true,
      lazy: true,
      textRules: [(v) => !!v || "Title is required"],
      employees: null,
      assignedToWithId: {},
      emailTemplateWithInfo: {},
      title: "",
      selected: {
        text: "",
        type: "",
        assignedTo: "",
        assignedToID: "",
        emailTemplate: "",
        emailTemplateId: "",
        emailTemplateName: "",
        description: "",
      },
    };
  },
  computed: {
    ...mapGetters("settingStore", [
      "GET_OpenEditTaskDialog",
      "GET_SelectedTask",
      "GET_SelectedTemplateWithCategory",
      "GET_MailTemplates",
      "GET_ErrorMessage",
    ]),

    employeesInfo() {
      try {
        const information = this.employees?.value.map((value) => {
          return (value = { displayName: value.displayName, id: value.id });
        });
        information.unshift({ displayName: "", id: "" });
        return information;
      } catch {
        return [];
      }
    },
    listOfEmailTemplate() {
      try {
        const list = [...this.GET_MailTemplates];
        list.unshift({ id: "", name: "" });
        return list;
      } catch {
        return [];
      }
    },
  },
  async created() {
    this.employees = await getAllCompanyUsers();
    await this.FETCH_MailTemplates();
    this.selectedTask();
  },

  watch: {
    alert(newValue) {
      if (newValue === false) {
        this.SET_ErrorMessage({});
      }
    },
  },
  methods: {
    ...mapMutations("settingStore", [
      "SET_OpenEditTaskDialog",
      "SET_SelectedTask",
      "SET_ErrorMessage",
    ]),
    ...mapActions("settingStore", ["EDIT_SelectedTask", "FETCH_MailTemplates"]),

    selectedTask() {
      const task = JSON.parse(JSON.stringify(this.GET_SelectedTask));
      this.selected = task;
      this.assignedToWithId = {
        displayName: this.selected.assignedTo,
        id: this.selected.assignedToID,
      };
      this.emailTemplateWithInfo = {
        id: this.selected.emailTemplateId,
        name: this.selected.emailTemplateName,
      };
    },

    reset() {
      this.$refs.form.reset();
      this.SET_SelectedTask({});
      this.SET_OpenEditTaskDialog(false);
    },

    async validate() {
      this.alert = false;
      this.$refs.form.validate();
      const template = {
        category: this.GET_SelectedTemplateWithCategory.category,
        id: this.GET_SelectedTemplateWithCategory.id,
      };

      (this.selected.assignedTo = this.assignedToWithId.displayName),
        (this.selected.assignedToID = this.assignedToWithId.id),
        (this.selected.emailTemplateId = this.emailTemplateWithInfo.id);
      this.selected.emailTemplateName = this.emailTemplateWithInfo.name;

      await this.EDIT_SelectedTask([this.selected, template]);

      if (this.GET_ErrorMessage.alert === true) {
        this.alert = true;
        return;
      }

      this.SET_SelectedTask({});
      this.SET_OpenEditTaskDialog(false);
    },
  },
};
</script>

<style scoped lang='scss'>
.form {
  padding: 16px 24px 10px;
}
</style>