import * as msal from "@azure/msal-browser";
import { clearTokens, setTokens } from "../api/api";
import { setGraphTokens, setUser } from "../api/msGraphApi";

const config = {
  auth: {
    clientId: process.env.VUE_APP_CLIENT_ID,
    authority: `${process.env.VUE_APP_AUTHORITY_BASE}/${process.env.VUE_APP_TENANT_ID}`,
    redirectUri: window.location.origin,
  },
  cache: {
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

const tokenConfig = {
  scopes: ["User.Read"],
};
const scopes = ["User.Read", "openid", "profile"];

const renewTokenRequest = {
  scopes: [process.env.VUE_APP_CLIENT_ID],
};

const msalInstance = new msal.PublicClientApplication(config);

const login = async () => {
  const response = await msalInstance.handleRedirectPromise();

  if (response !== null) {
    await setAccountInfo(response);

    return true;
  } else {
    const currentAccount = msalInstance.getAllAccounts();
    if (currentAccount.length === 0) {
      msalInstance.loginRedirect(tokenConfig);

      return false;
    } else if (currentAccount.length) {
      try {
        const silentResponse = await msalInstance.acquireTokenSilent({
          tokenConfig,
          account: currentAccount[0],
        });
        await setAccountInfo(silentResponse);

        return true;
      } catch (error) {
        return false;
      }
    }
  }
};

const setAccountInfo = async (response) => {
  setTokens(response.idToken, response.tokenType);
  setGraphTokens(response.accessToken, response.tokenType);
  setUser(response.account.username);

  const account = {
    id: response.account.username,
    name: response.account.name,
    username: response.account.username,
    azureId: response.account.localAccountId,
    role: response.idTokenClaims.roles,
  };

  sessionStorage.setItem("account", JSON.stringify(account));
};

const logout = () => msalInstance.logoutRedirect(tokenConfig);

const isAuthenticated = async () => {
  try {
    await msalInstance.acquireTokenSilent(renewTokenRequest);
    return true;
  } catch (error) {
    return false;
  }
};

const getToken = async () => {
  const currentAccounts = msalInstance.getAllAccounts();
  // const token = await msalInstance.acquireTokenSilent(renewTokenRequest);

  const token = await msalInstance.acquireTokenSilent({
    renewTokenRequest,
    scopes,
    account: currentAccounts[0],
  });
  const rawIdToken = token.idToken;

  if (!rawIdToken) {
    throw new Error("Invalid token");
  }
  return rawIdToken;
};

const getAuthHeader = async () => {
  const token = await getToken();

  return { Authorization: `${token}` };
};

const setAuthInfo = async (response) => {
  setTokens(response.idToken, response.accessToken);

  const account = {
    id: response.account.username,
    name: response.account.name,
  };

  sessionStorage.setItem("account", JSON.stringify(account));
};

export const auth = async () => {
  const response = await msalInstance.handleRedirectPromise();

  if (response !== null) {
    await setAuthInfo(response);

    return true;
  } else {
    const currentAccounts = msalInstance.getAllAccounts();

    if (currentAccounts.length === 0) {
      msalInstance.loginRedirect({ scopes });

      return false;
    } else if (currentAccounts.length) {
      try {
        const silentResponse = await msalInstance.acquireTokenSilent({
          scopes,
          account: currentAccounts[0],
        });

        await setAuthInfo(silentResponse);

        return true;
      } catch (error) {
        clearTokens();
        location.reload();
        return false;
      }
    }
  }
};

export { login, logout, isAuthenticated, getToken, getAuthHeader };
