<template>
  <v-app-bar app color="black" clipped-left dark>
    <div class="d-flex align-center">
      <router-link
        to="/"
        tag="div"
        class="v-toolbar__title"
        style="cursor: pointer"
      >
        BoardNow
      </router-link>
    </div>

    <v-spacer></v-spacer>
    <div>
      <p style="color: black">Hej från Danielle Hamrin</p>
    </div>
  </v-app-bar>
</template>

<script>
  import { mapGetters } from "vuex";
  import { logout } from "../../services/auth";

  export default {
    computed: {
      ...mapGetters(["user"]),
    },
    methods: {
      logout,
    },
  };
</script>

<style></style>
