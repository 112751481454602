<template>
  <v-alert type="error" border="bottom" dismissible>{{ text }}</v-alert>
</template>

<script>
export default {
  props: { text: { type: String, default: "" } },
};
</script>

<style lang='scss' scoped>
</style>

