import {
  postMsGraphApiData,
  getMsGraphApiData,
  deleteMsGraphApiData,
  graphGetBlob,
  patchMsGraphApiData,
  putMsGraphApiData,
} from "../api/msGraphApi";
import { getApiData } from "../api/api";

export const createPersonToAzure = async (azureUser, teamGroupIds) => {
  try {
    //remove ÅÄÖ from user userPrincipalName and users MAIL
    let azureUserEmail = azureUser.userPrincipalName.toLowerCase();
    if (azureUserEmail.includes("å", "ä", "ö")) {
      azureUserEmail = azureUserEmail
        .replace(/å/g, "a")
        .replace(/ä/g, "a")
        .replace(/ö/g, "o");
    }

    azureUser.mail = azureUserEmail;
    azureUser.userPrincipalName = azureUserEmail;

    //MUST FIRST CHECK IF USER ALREADY EXIST!!!
    const getUserResponse = await getMsGraphApiData(
      `/users?$filter=startswith(mail,'${azureUserEmail}')`
    );

    //IF USER EXIST
    if (getUserResponse != undefined && getUserResponse.value.length >= 1) {
      //USER EXIST STOP PRODUCTION!!!!
      return "exists";
    }

    //CREATE USER
    const createUserResponse = await postMsGraphApiData("/users", azureUser);

    if (createUserResponse != undefined) {
      const userID = createUserResponse.id;

      const addLicenses = {
        addLicenses: [
          {
            disabledPlans: [],
            skuId: "f245ecc8-75af-4f8e-b61f-27d8114de5f3",
          },
        ],
        removeLicenses: [],
      };

      const AssignLicenseResponse = await postMsGraphApiData(
        `/users/${userID}/assignLicense`,
        addLicenses
      );
      if (AssignLicenseResponse != undefined) {
        //TODOS
        //ADD USER TO DIFFRENT GROUPS!
        //ADD the team manager/leader to the person from the group owner?!

        //add REQUIER MFA group to user
        teamGroupIds.push("Requier MFA :a48f2def-3afb-4223-9c34-235d61b8fe00");

        for (let i = 0; i < teamGroupIds.length; i++) {
          const splitedValue = teamGroupIds[i].split(":");
          const groupID = splitedValue[1];
          const userToAddToGroup = {
            "@odata.id": `https://graph.microsoft.com/v1.0/directoryObjects/${userID}`,
          };

          //TODO IS NO AWAITING THIS CALL!!!! Shirzad
          const addUserToGroupsResponse = await postMsGraphApiData(
            `/groups/${groupID}/members/$ref`,
            userToAddToGroup
          );
          if (addUserToGroupsResponse != undefined) {
            //do nothing
          } else {
            //IF can not add user to groups, canel operation and delete USER

            deleteMsGraphApiData(`/users/${userID}`);
            return "Could not crate user, something went wrong with creating groups";
          }
        }
      } else {
        //ELSE SOMETHING WENT WRONG; REMOVE CRETED USER???

        deleteMsGraphApiData(`/users/${userID}`);
        return "Could not create user";
      }
    } else {
      return "Could not create user";
    }

    return await createUserResponse;
  } catch (error) {
    return error;
  }
};

//

export const getAllTeams = async () => {
  const response = await getApiData(`getAllTeams`);

  if (!response) {
    return undefined;
  }
  return await response.state;
};

// get all groups

export const getAllOrgnazionGroups = async () => {
  const response = await getMsGraphApiData("/groups");

  if (!response) {
    return undefined;
  }
  return await response;
};

export const getAllgroupsWithExpand = async () => {
  const response = await getMsGraphApiData(
    `/groups?$select=id,description,displayName,mail,mailNickname&$expand=owners($select=id,city,companyName,department,displayName,givenName,surname,jobTitle,mail,mailNickname,mobilePhone,userPrincipalName,jobTitle)`
  );
  return await response;
};

/*export const getAllCompanyUsers = async () => {
  const response = await getMsGraphApiData(
    `/users?$count=true&$orderby=displayName&$filter=((endsWith(mail,'@geshdo.com'))and (accountEnabled eq true))`
  );
  return await response;
};*/
export const getAllCompanyUsers = async () => {
  const response = await getMsGraphApiData(
    `/users?$count=true&$orderby=displayName&$filter=((endsWith(mail,'@geshdo.com'))and (accountEnabled eq true))&$select=assignedLicenses,displayName,id,mail,jobTitle,officeLocation`
  );

  const users = response.value
    .map((user) => {
      if (user.assignedLicenses.length > 0) {
        return user;
      }
    })
    .filter(Boolean);

  return { ...response, value: users };
};

export const getAzureUser = async (email) => {
  const response = await getMsGraphApiData(
    `/users/${email}?$select=displayName,streetAddress,city,postalCode,state,country,department,mobilePhone,id `
  );
  return await response;
};

export const getAzureUsersManager = async (email) => {
  const response = await getMsGraphApiData(`/users/${email}/manager`);
  return await response;
};

export const getAllCompanyUsersOdata = async (oDataLink) => {
  const response = await getMsGraphApiData(`/${oDataLink}`);
  return await response;
};

export const getAllCompanyUsersPhotos = async (azureId) => {
  const response = await getMsGraphApiData(`/users/${azureId}/photo/$value`);
  return await response;
};

export const getProfilePicture = async (id) => {
  return graphGetBlob(`/users/${id}/photos/96x96/$value`).then((blob) => {
    return blob ? window.URL.createObjectURL(blob) : undefined;
  });
};

export const addManagerToUser = async (userId, managerId) => {
  const data = {
    "@odata.id": `https://graph.microsoft.com/v1.0/users/${managerId}`,
  };
  const response = await putMsGraphApiData(
    `/users/${userId}/manager/$ref`,
    data
  );
  return await response;
};

export const updateUser = async (email, data) => {
  const response = await patchMsGraphApiData(`/users/${email}`, data);
  return await response;
};

// get admin and managers for dev and prod 


export const getAllGroupMembers = async (groupId) => {
  const response = await getMsGraphApiData(`/groups/${groupId}/members?$count=true`)
  return await response;
}
