<template>
  <v-row justify="center"
    ><v-dialog v-model="GET_OpenEditTemplateDialog" persistent max-width="600px"
      ><v-card
        ><v-card-title class="header font-weight-thin text-h5"
          >Do you want to edit &nbsp;
          <b>{{ GET_SelectedTemplateWithCategory.name }}</b> &nbsp;
          ?</v-card-title
        ><v-card-subtitle class="mt-2"
          >Editing a template here will not affect the templates or the task on
          the ones that are in the progress of being Onboarded. The changes will
          only effect new employees.</v-card-subtitle
        ><v-form
          ref="form"
          v-model="valid"
          :lazy-validation="lazy"
          class="form"
        >
          <v-text-field
            class="text-field"
            v-model="name"
            :items="editName"
            label="Edit template name"
          ></v-text-field>
          <v-spacer></v-spacer>
          <ErrorMessageAlert
            v-if="alert"
            v-model="alert"
            :text="GET_ErrorMessage.message"
          />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="action-btn"
              depressed
              color="primary"
              @click="reset()"
            >
              Cancel
            </v-btn>
            <v-btn
              class="action-btn"
              depressed
              color="primary"
              :disabled="name == null || name.length < 2"
              @click="editTemplate()"
            >
              Edit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card></v-dialog
    ></v-row
  >
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ErrorMessageAlert from "../MessageToUser/ErrorMessageAlert.vue";
export default {
  components: { ErrorMessageAlert },
  data() {
    return {
      alert: false,
      valid: true,
      lazy: true,
      name: "",
      nameRules: [(v) => !!v || "Name is required"],
    };
  },
  computed: {
    ...mapGetters("settingStore", [
      "GET_TemplateType",
      "GET_OpenEditTemplateDialog",
      "GET_SelectedTemplateWithCategory",
      "GET_ErrorMessage",
    ]),

    editName() {
      return this.GET_SelectedTemplateWithCategory.name;
    },
  },

  created() {
    this.name = this.GET_SelectedTemplateWithCategory.name;
  },
  watch: {
    alert(newValue) {
      if (newValue === false) {
        this.SET_ErrorMessage({});
      }
    },
  },

  methods: {
    ...mapMutations("settingStore", [
      "SET_OpenEditTemplateDialog",
      "SET_ErrorMessage",
    ]),
    ...mapActions("settingStore", ["EDIT_SelectedTemplateWithCategory"]),

    reset() {
      this.$refs.form.reset();
      this.SET_OpenEditTemplateDialog(false);
    },

    async editTemplate() {
      this.alert = false;
      const templateNameWithTemplateType = {
        category: this.GET_TemplateType,
        name: this.name,
      };
      const itemId = this.GET_SelectedTemplateWithCategory.id;
      await this.EDIT_SelectedTemplateWithCategory({
        itemId,
        templateNameWithTemplateType,
      });
      if (this.GET_ErrorMessage.alert === true) {
        this.alert = true;
        return;
      }

      this.SET_OpenEditTemplateDialog(false);
    },
  },
};
</script>

<style scoped lang='scss'>
.header {
  justify-content: center;
}
.form {
  padding: 16px 24px 10px;
}
.text-field {
  font-weight: 300;
}
.action-btn {
  font-weight: 300;
}
</style>