import { getApiData, postApiData, putApiData, deleteApiData } from "../api/api";
import store from "../store";

//people api calls
const getPeople = async () => {
  store.commit("onboardingPeople/setPeopleLoading", true);
  const response = await getApiData("people/on");

  if (!response) {
    store.commit("onboardingPeople/setPeopleLoading", false);
    return undefined;
  }
  store.commit("onboardingPeople/setPeople", response.state);
  store.commit("onboardingPeople/setPeopleLoading", false);
  return await response.state; //  only get out the people
};

const getOffboardingPeople = async () => {
  const response = await getApiData("people/off");

  if (!response) {
    return undefined;
  }
  store.commit("offboardingPeopleStateObejct/setPeople", response.state);

  return await response.state; //  only get out the people
};

const getPerson = async (id) => {
  const response = await getApiData(`people/on/${id}`);

  if (!response) {
    return undefined;
  }
  return await response.state; // only get out the person
};

const addPerson = async (endpoint, person) => {
  // add both off and on boarding people
  const postApiDataResponse = await postApiData(endpoint, person);

  if (!postApiDataResponse) {
    return undefined;
  }

  return await { postApiDataResponse, person };
};

const updatePerson = async (body) => {
  const response = await putApiData("people/on/update", body);

  if (!response) {
    return undefined;
  }

  return await { response, body };
};

const deletePerson = async (id) => {
  const response = await deleteApiData(`people/on/delete/${id}`);

  if (!response) {
    return undefined;
  }

  return await { response };
};

const addCompleted = async (endpoint, body) => {
  const response = await putApiData(endpoint, body);

  if (!response) {
    return undefined;
  }

  return await { response, body };
};

const addCompletedWithText = async (body) => {
  const response = await putApiData("people/on/task/completed/text", body);

  if (!response) {
    return undefined;
  }

  return await { response };
};

const removeCompleted = async (endpoint, body) => {
  const response = await putApiData(endpoint, body);

  if (!response) {
    return undefined;
  }

  return await { response, body };
};

const addDeleted = async (body) => {
  const response = await putApiData("people/on/task/delete", body);

  if (!response) {
    return undefined;
  }

  return await { response, body };
};

// manager api calls
const getManagement = async () => {
  const response = await getApiData("manager");

  if (!response) {
    return undefined;
  }

  return await response.state[0]; // get all managers
};

const updateManagers = async (body) => {
  const response = await putApiData("manager", body);

  if (!response) {
    return undefined;
  }

  return await { response, body };
};

const addManager = async (body) => {
  const response = await putApiData("manager/add", body);

  if (!response) {
    return undefined;
  }

  return await { response };
};

// task settings

const getOnBoardingTasks = async () => {
  const response = await getApiData("task/onBoard");

  if (!response) {
    return undefined;
  }
  store.commit("onboardingTasks/setOnBoardingTasks", response.state);
  return await response.state; // get all base tasks
};

const getOffBoardingTasks = async () => {
  const response = await getApiData("task/offBoard");

  if (!response) {
    return undefined;
  }
  store.commit("offboardingTasks/setOffBoardingTasks", response.state);
  return await response.state; // get all base tasks
};

const addTask = async (task) => {
  const response = await postApiData("task/add", task);

  if (!response) {
    return undefined;
  }
  if (task.variant === "off") {
    store.commit("offboardingTasks/addOffTask", task);
  }
  if (task.variant === "on") {
    store.commit("onboardingTasks/addOnTask", task);
  }

  return await { response, task };
};

const updateBaseTask = async (body) => {
  const response = await putApiData("task/update", body);

  if (!response) {
    return undefined;
  }

  return await { response, body };
};

// mail settings

const getMailText = async () => {
  const response = await getApiData("mail/");

  if (!response) {
    return undefined;
  }

  return await response.state; // get all base tasks
};

const addMailText = async (text) => {
  const response = await postApiData("mail/add", text);

  if (!response) {
    return undefined;
  }

  return await { response, text };
};

const updateMailText = async (text) => {
  const response = await putApiData("mail/update", text);

  if (!response) {
    return undefined;
  }

  return await { response, text };
};

export {
  getPeople,
  getOffboardingPeople,
  addPerson,
  deletePerson,
  addCompleted,
  removeCompleted,
  updatePerson,
  getPerson,
  addCompletedWithText,
  getManagement,
  updateManagers,
  addManager,
  getOnBoardingTasks,
  getOffBoardingTasks,
  updateBaseTask,
  addTask,
  addDeleted,
  getMailText,
  updateMailText,
  addMailText,
};
