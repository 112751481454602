<template>
  <v-row>
    <v-dialog v-model="GET_OpenSelectedMailTemplateFromPersonTask" persistent
      ><v-card class="card">
        <v-form ref="form" v-model="valid" :lazy-validation="lazy" class="form">
          <v-row class="text-fields">
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="selected.name"
                :rules="textRules"
                label="Title*"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="selected.subject"
                :rules="textRules"
                label="Subject*"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-combobox
                v-model="selected.toAddress"
                @focus="$event.target.click()"
                :items="employeesInfo"
                :rules="emailRules"
                item-text="mail"
                label="Mail to"
                :persistent-hint="true"
                hint="You can either select or write an email adress"
                return-object
                attach
              ></v-combobox>
            </v-col>
          </v-row>

          <v-spacer></v-spacer>
          <EmailEditor
            ref="emailEditor"
            v-on:load="editorLoaded"
            :options="{ projectId: '207608' }"
          />

          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <small class="indicate">*indicates required field</small>
          <ErrorMessageAlert
            v-if="alert"
            v-model="alert"
            :text="GET_OnOffBoardingErrorMessage.message"
          />
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn class="btn" depressed color="primary" @click="reset()">
              Cancel
            </v-btn>
            <v-btn
              class="btn"
              depressed
              color="primary"
              :disabled="isDisabled"
              @click="editMailTemplate()"
            >
              Edit design
            </v-btn>
            <v-btn
              class="btn"
              depressed
              color="primary"
              @click="sendEmailTemplate()"
            >
              Send email
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { EmailEditor } from "vue-email-editor";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { getAllCompanyUsers } from "../../services/msGraphActions";
import { simpleMail } from "../../services/mails";
import ErrorMessageAlert from "../MessageToUser/ErrorMessageAlert.vue";
export default {
  components: { EmailEditor, ErrorMessageAlert },
  data() {
    return {
      alert: false,
      valid: true,
      lazy: true,
      textRules: [(v) => !!v || "Title is required"],
      employees: null,
      selected: {
        name: "",
        subject: "",
        toAddress: "",
        templateData: {},
      },
    };
  },
  computed: {
    ...mapGetters("onBoardingStore", [
      "GET_SelectedPersonWithTasks",
      "GET_OpenSelectedMailTemplateFromPersonTask",
      "GET_SelectedMailTemplateFromPersonTask",
      "GET_SelectedTaskWithMail",
      "GET_OnOffBoardingErrorMessage",
    ]),
    employeesInfo() {
      try {
        const information = this.employees?.value.map((value) => {
          return (value = value.mail);
        });
        information.unshift("Candidate private email");
        information.unshift("");

        return information;
      } catch {
        return [];
      }
    },

    emailRules() {
      return [
        (v) => {
          if (v === "") return true;
          if (v === "Candidate private email") return true;
          if (/.+@.+/.test(v)) return true;

          return "E-mail must be valid";
        },
      ];
    },
    isDisabled() {
      return (
        this.selected.name == "" ||
        this.selected.subject == "" ||
        (this.selected.toAddress !== "" &&
          this.selected.toAddress !== "Candidate private email" &&
          !/.+@.+/.test(this.selected.toAddress))
      );
    },
  },
  async created() {
    this.employees = await getAllCompanyUsers();
  },
  watch: {
    alert(newValue) {
      if (newValue === false) {
        this.SET_OnOffBoardingErrorMessage({});
      }
    },
  },
  methods: {
    ...mapMutations("onBoardingStore", [
      "SET_OpenSelectedMailTemplateFromPersonTask",
      "SET_SelectedMailTemplateFromPersonTask",
      "SET_OnOffBoardingErrorMessage",
    ]),
    ...mapActions("onBoardingStore", [
      "EDIT_SelectedMailTemplateFromPersonTask",
      "EDIT_TaskStatus",
    ]),

    async editorLoaded() {
      const task = JSON.parse(
        JSON.stringify(this.GET_SelectedMailTemplateFromPersonTask)
      );
      this.selected = task;

      if (Object.keys(this.selected.templateData).length > 0) {
        this.$refs.emailEditor.editor.loadDesign(this.selected.templateData);
      } else {
        console.log("something is wrong");
      }
    },
    reset() {
      this.$refs.form.reset();
      this.SET_SelectedMailTemplateFromPersonTask({});
      this.$refs.emailEditor.editor.loadBlank({
        backgroundColor: "#e7e7e7",
      });
      this.SET_OpenSelectedMailTemplateFromPersonTask(false);
    },

    async editMailTemplate() {
      this.alert = false;
      this.$refs.form.validate();

      await this.$refs.emailEditor.editor.saveDesign(async (design) => {
        this.selected.templateData = design;
        const template = {
          type: "onBoarding",
          personId: this.GET_SelectedPersonWithTasks.id,
          selected: this.selected,
        };
        await this.EDIT_SelectedMailTemplateFromPersonTask(template);
        if (this.GET_OnOffBoardingErrorMessage.alert === true) {
          this.alert = true;
          return;
        }
        this.SET_OpenSelectedMailTemplateFromPersonTask(false);

        this.$refs.emailEditor.editor.loadBlank({
          backgroundColor: "#e7e7e7",
        });
      });
    },

    async sendEmailTemplate() {
      this.$refs.form.validate();
      if (this.selected.toAddress === "Candidate private email") {
        this.selected.toAddress =
          this.GET_SelectedPersonWithTasks.privateInfo.email;
      }

      this.$refs.emailEditor.editor.exportHtml(async (data) => {
        await simpleMail(this.selected.subject, data.html, [
          this.selected.toAddress,
        ]);
        const infoToSend = this.GET_SelectedTaskWithMail;

        const updatedTask = { completed: true };
        await this.EDIT_TaskStatus({
          infoToSend,
          updatedTask,
        });
      });
      this.editMailTemplate();
    },

    /* add error messages to the fetch, post, and send 
    "could not create a mailText"
    Added mailText
    */
  },
};
</script>

<style scoped>
.card ::v-deep iframe {
  height: 70vh !important;
}
.form {
  padding: 16px 24px 10px;
}
.text-fields {
  padding: 30px 25px 10px;
}
.indicate {
  margin-left: 24px;
}
.btn {
  font-weight: 300;
}
</style>{}