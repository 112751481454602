import Vue from "vue";

const onboardingTasks = {
  namespaced: true,
  state: {
    tasks: null,
  },
  mutations: {
    setOnBoardingTasks(state, newData) {
      state.tasks = newData;
    },
    addOnTask(state, newTask) {
      state.tasks.push(newTask.task);
    },
    setNewTimeFrame(state, payLoad) {
      const task = state.tasks.find((x) => x.id === payLoad.id);

      Vue.set(task, "type", payLoad.type);
    },
    
  }, // change state
  getters: {
    getMonth: (state) => {
      return state.tasks.filter((task) => task.type === "month");
    },
    getWeek: (state) => {
      return state.tasks.filter((task) => task.type === "week");
    },
    getDay: (state) => {
      return state.tasks.filter((task) => task.type === "day");
    },
  },
     // get specific state
  actions: {}, // tell what to do
};

export default onboardingTasks;
